export const formatAsPercentage = (number: number) => {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number)
}

export const safeFloatToPercentage = (nb: number) => {
  return Math.round((nb * 100 + Number.EPSILON) * 100) / 100
}
