import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GET_MERCHANT_YIELD_INFORMATIONS = gql`
  query GetMerchantYieldInformations {
    getMerchantYieldInformations {
      ... on GetMerchantYieldInformationsOutput {
        merchantId
        isEnabled
        yieldPercentage
        currentYieldPercentage
        minTransactionCardCount
        promotionalYieldPercentage
        promotionalYieldDaysLeft
        currentTotalYield
        payouts {
          createdAt
          amount
          status
        }
        firstPayoutDate
        totalEarnedYield
        yieldConfigurationUpdatedAt
        transactionCardCountNeeded
      }

      ... on GqlHeroError {
        errorCode
        message
        detail
      }

      ... on ValidationErrors {
        errorCode

        validationErrors {
          path
          validationError
        }
      }
    }
  }
`

type GetMerchantYieldInformationsOutput = {
  merchantId: string
  isEnabled: boolean
  yieldPercentage: number
  currentYieldPercentage: number
  minTransactionCardCount: number
  promotionalYieldPercentage: number
  promotionalYieldDaysLeft: number
  currentTotalYield: number
  payouts: {
    createdAt: string
    amount: number
    status: string
  }[]
  firstPayoutDate: string | null
  totalEarnedYield: number
  yieldConfigurationUpdatedAt: string
  transactionCardCountNeeded: number
}

export const useGetMerchantYieldInformations = (skip = false) => {
  return useHeroQuery<GetMerchantYieldInformationsOutput>({
    gqlQuerySchema: GET_MERCHANT_YIELD_INFORMATIONS,
    skip,
  })
}
