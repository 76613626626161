import { gql } from "@apollo/client"
import { useMemo } from "react"
import { useHeroQuery } from "../../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { CommonTFunction } from "../../../../../../01_technical/translations"
import { OperationType, ProductType } from "../../../../00_shared/business"
import { FormattedDetails } from "../../utils"

interface IssuingCardTransaction {
  id: string
}

interface IssuingCardAuthorization {
  id: string
  businessId: string
  businessType: string // You can replace `string` with an enum if you have one for business types
  amount: number
  label: string
  createdAt: Date
}

interface IssuingCard {
  id: string
  lastCardDigits: string
  cardholderName: string
  businessAccountId: string
  paymentAccountName: string
}

interface GetIssuingCardTransactionByOriginLedgerMovementIdOutput {
  transaction: IssuingCardTransaction
  authorization: IssuingCardAuthorization
  card: IssuingCard
  createdAtFormatted: string
  amountEuros: string
}

const GET_ISSUING_CARD_TRANSACTION_BY_ORIGIN_LEDGER_MOVEMENT_ID = gql`
  query GetIssuingCardTransactionByOriginLedgerMovementId($originLedgerMovementId: String!) {
    getIssuingCardTransactionByOriginLedgerMovementId(originLedgerMovementId: $originLedgerMovementId) {
      ... on GetIssuingCardTransactionByOriginLedgerMovementIdOutput {
        transaction {
          id
        }
        card {
          id
          lastCardDigits
          cardholderName
          businessAccountId
          paymentAccountName
        }
        authorization {
          id
          businessId
          businessType
          amount
          label
          createdAt
        }
        createdAtFormatted
        amountEuros
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

export const useIssuingCardTransaction = (productType: ProductType, originLedgerMovementId?: string) => {
  const {
    data: transactionDetails,
    loading: loadingTransaction,
    error: errorTransaction,
  } = useHeroQuery<GetIssuingCardTransactionByOriginLedgerMovementIdOutput>({
    gqlQuerySchema: GET_ISSUING_CARD_TRANSACTION_BY_ORIGIN_LEDGER_MOVEMENT_ID,
    fetchPolicy: "cache-and-network",
    variables: {
      originLedgerMovementId,
    },
    skip: !originLedgerMovementId || productType !== ProductType.ISSUING,
  })

  const { card, transaction, authorization, createdAtFormatted, amountEuros } = transactionDetails || {}
  const label = authorization?.label

  const formatDetails = useMemo(
    () =>
      (t: CommonTFunction): FormattedDetails => {
        if (!card || !label || !amountEuros || !createdAtFormatted) return {} as FormattedDetails // Safeguard for incomplete data

        return {
          operationItems: [
            {
              label: t("issuing.operation.details.date"),
              value: createdAtFormatted,
            },
            {
              label: t("issuing.operation.details.paymentAccount"),
              value: card.paymentAccountName,
            },
            {
              label: t("issuing.operation.details.method"),
              value: t("issuing.operation.details.byCard"),
            },
            {
              label: t("issuing.operation.details.card"),
              value: card.lastCardDigits,
            },
            {
              label: t("issuing.operation.details.cardholder"),
              value: card.cardholderName,
            },
          ],
          status: [
            { label: t("issuing.operation.details.debitDate"), value: createdAtFormatted },
            { label: t("issuing.operation.details.waitingForClearing"), value: "-" },
          ],
          label: label,
          pageTitle: t("account.operation.details.title"),
          amount: amountEuros,
          createdAt: createdAtFormatted,
          operationType: OperationType.CARD,
        }
      },
    [card, label, amountEuros, createdAtFormatted], // Memoize based on these dependencies
  )

  return { card, transaction, authorization, loadingTransaction, errorTransaction, formatDetails }
}
