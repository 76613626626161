import { ErrorBlock, Typography } from "@hero/krypton"
import styled from "styled-components"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { Spacer } from "../../../00_shared/components/Spacer"
import { centsToEuros, i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import { DateStyle, isDifferentDay, toInternationalDate } from "../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import {
  DashboardSectionHeader as Header,
  DashboardSectionHeaderIconWrapper as HeaderIconWrapper,
  DashboardSectionTitle as HeaderTitle,
  DashboardSection as Section,
} from "../../../00_shared/components/krypton2/DashboardSection"
import {
  Table,
  TableBody,
  TableCell,
  TableGroup,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "../../../00_shared/components/krypton2/Table"
import { apRegulatedBreakpoints as breakpoints } from "../utils"
import {
  ApRegulatedItem,
  ApRegulatedStatusEnum,
  useGetAcceleratedPayoutList,
  useGetMemoizedGroups,
} from "../hooks/getAcceleratedPayoutList"
import { ExportSheet } from "./ExportSheet"
import { StatusBadge } from "./StatusBadge"

export const AcceleratedPayoutsSection = ({
  setDisplayedDetailsId,
}: {
  setDisplayedDetailsId: (id: string) => void
}) => {
  const { t } = useDashboardTranslation()

  const { data, error, loading } = useGetAcceleratedPayoutList({
    fakeData: false,
    queryVariables: {
      filters: {
        fromDate: "2000-01-01T00:00:00Z",
        toDate: "2100-01-01T00:00:00Z",
      },
      pagination: {
        pageNumber: 1,
        pageSize: 10,
      },
    },
  })

  return (
    <Section>
      <Header>
        <CustomHeaderContentWrapper>
          <HeaderTitle>
            <HeaderIconWrapper>AP</HeaderIconWrapper>
            {t("apRegulated.dashboard.apListSection.title")}
          </HeaderTitle>

          <div>
            <ExportSheet />
          </div>
        </CustomHeaderContentWrapper>
      </Header>

      <Spacer $height="1.5rem" />

      {loading && (
        <>
          <Spacer $height="1.5rem" />
          <CenteredLoading />
          <Spacer $height="1.5rem" />
        </>
      )}

      {error && <ErrorBlock $margin="0">{error.translatedMessage}</ErrorBlock>}

      {data && <TableMobile data={data.apRegulatedList} setDisplayedDetailsId={setDisplayedDetailsId} />}

      {data && <TableDesktop data={data.apRegulatedList} setDisplayedDetailsId={setDisplayedDetailsId} />}
    </Section>
  )
}

const CustomHeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TableMobile = ({
  data,
  setDisplayedDetailsId,
}: {
  data: ApRegulatedItem[]
  setDisplayedDetailsId: (id: string) => void
}) => {
  const { i18n } = useDashboardTranslation()
  const groups = useGetMemoizedGroups(data)

  return (
    <TableMobileWrapper>
      <Table>
        <TableBody>
          {groups.map((group) => {
            const aps = data.filter((item) => item.group.id === group.id)

            return (
              <TableGroup key={group.id} label={group.label}>
                {aps.map((ap) => {
                  return (
                    <TableRow
                      onClick={() => {
                        setDisplayedDetailsId(ap.id)
                      }}
                    >
                      <TableCell>
                        <MobileCellContent>
                          <div>
                            <MarketplaceWrapper>
                              <MarketplaceLogo src={ap.marketplace.logoUrl || ""} />
                              <Typography $variant="body-4-regular">{ap.marketplace.name}</Typography>
                            </MarketplaceWrapper>

                            <Typography $variant="body-4-regular">
                              {toInternationalDate({
                                date: ap.periodStartAt,
                                language: i18n.language,
                                style: DateStyle.TEXTUAL,
                              })}
                            </Typography>
                          </div>
                          <MobileCellContentRight>
                            <FinancedAmount $isCanceled={ap.status === ApRegulatedStatusEnum.OPENING_CANCELLED}>
                              {i18nCurrencyFormatter(centsToEuros(ap.financedAmount), i18n.language)}
                            </FinancedAmount>

                            <StatusBadge status={ap.status}></StatusBadge>
                          </MobileCellContentRight>
                        </MobileCellContent>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableGroup>
            )
          })}
        </TableBody>
      </Table>
    </TableMobileWrapper>
  )
}

const TableMobileWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.tablet}) {
    display: block;
  }
`

const MobileCellContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MobileCellContentRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const TableDesktop = ({
  data,
  setDisplayedDetailsId,
}: {
  data: ApRegulatedItem[]
  setDisplayedDetailsId: (id: string) => void
}) => {
  const { t, i18n } = useDashboardTranslation()
  const groups = useGetMemoizedGroups(data)

  return (
    <TableDesktopWrapper>
      {data && (
        <Table>
          <TableHead>
            <TableHeaderCell>{t("apRegulated.dashboard.apListSection.period")}</TableHeaderCell>
            <TableHeaderCell>{t("apRegulated.dashboard.apListSection.marketplace")}</TableHeaderCell>
            <TableHeaderCell>{t("apRegulated.dashboard.apListSection.status")}</TableHeaderCell>
            <TableHeaderCell>{t("apRegulated.dashboard.apListSection.transfert")}</TableHeaderCell>
          </TableHead>

          <TableBody>
            {groups &&
              groups.map((group) => {
                const aps = data.filter((item) => item.group.id === group.id)

                return (
                  <TableGroup key={group.id} label={group.label}>
                    {aps.map((ap) => {
                      return (
                        <TableRow
                          onClick={() => {
                            setDisplayedDetailsId(ap.id)
                          }}
                        >
                          <TableCell>
                            <Typography $variant="body-4-regular">
                              {toInternationalDate({
                                date: ap.periodStartAt,
                                language: i18n.language,
                                style: DateStyle.TEXTUAL,
                              })}
                            </Typography>
                            {isDifferentDay(ap.periodStartAt, ap.periodEndAt) && (
                              <PeriodEndTypography>
                                -{" "}
                                {toInternationalDate({
                                  date: ap.periodEndAt,
                                  language: i18n.language,
                                  style: DateStyle.TEXTUAL,
                                })}
                              </PeriodEndTypography>
                            )}
                          </TableCell>
                          <TableCell>
                            <MarketplaceWrapper>
                              <MarketplaceLogo src={ap.marketplace.logoUrl || ""} />
                              <Typography $variant="body-4-regular">{ap.marketplace.name}</Typography>
                            </MarketplaceWrapper>
                          </TableCell>
                          <TableCell>
                            <StatusBadge status={ap.status}></StatusBadge>
                          </TableCell>
                          <TableCell>
                            <TransferWrapper>
                              <FinancedAmount $isCanceled={ap.status === ApRegulatedStatusEnum.OPENING_CANCELLED}>
                                {i18nCurrencyFormatter(centsToEuros(ap.financedAmount), i18n.language)}
                              </FinancedAmount>
                              <Typography $variant="caption-2" $color="grey.$400">
                                {ap.beneficiaryLabel}
                              </Typography>
                            </TransferWrapper>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableGroup>
                )
              })}
          </TableBody>
        </Table>
      )}
    </TableDesktopWrapper>
  )
}

const TableDesktopWrapper = styled.div`
  display: block;
  margin-bottom: -1rem;

  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const PeriodEndTypography = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$400};
`

const FinancedAmount = styled(Typography).attrs({ $variant: "body-4-semibold" })<{ $isCanceled?: boolean }>`
  color: ${({ theme, $isCanceled: isCanceled }) => (isCanceled ? theme.colors.grey.$400 : "inherit")};
  text-decoration: ${({ $isCanceled: isCanceled }) => (isCanceled ? "line-through" : "none")};
`

const MarketplaceLogo = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`

const MarketplaceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.375rem;
`

const TransferWrapper = styled.div``
