import { isValidIBAN } from "ibantools"
import { isValidPhoneNumber } from "react-phone-number-input"
import * as z from "zod"
import { CommonTFunction } from "../../01_technical/translations"

export const IbanSchema = (t: CommonTFunction) => {
  return z
    .string()
    .min(14, { message: t("formValidation.string.tooShort", { min: 14 }) })
    .max(34, { message: t("formValidation.string.tooLong", { max: 34 }) })
    .superRefine((iban, ctx) => {
      if (!isValidIBAN(iban)) {
        ctx.addIssue({ code: z.ZodIssueCode.custom, message: t("formValidation.iban.invalid") })
      }
    })
}

export const EmailSchema = (t: CommonTFunction) => {
  return z.string({ message: t("formValidation.common.invalid") }).email({
    message: t("formValidation.email.invalid"),
  })
}

export const PhoneSchema = (t: CommonTFunction) => {
  return z.string({ message: t("formValidation.common.invalid") }).superRefine((phone, ctx) => {
    if (!isValidPhoneNumber(phone)) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, message: t("formValidation.phone.invalid") })
    }
  })
}

/**
 * Strict banking string schema.
 * Useful for:
 * - Wire label
 * - Remittance informations lines
 * - Adresses lines
 * - Creditor name
 * - etc.
 */
export const StrictBankingString = (t: CommonTFunction) => {
  // Characters allowed: a-z A-Z 0-9 space / - ? : ( ) . , ' + _
  const onlyAllowedCharacters = z.string().regex(/^[a-zA-Z0-9\s/\-?:(\\).,'+_]*$/, {
    message: t("formValidation.strictBankingString.invalidCharacters"),
  })

  // Cannot start with a : or -
  const noForbiddenFirstChar = z.string().regex(/^[^:-]/, {
    message: t("formValidation.string.invalidStart"),
  })

  // Max 35 characters.
  const max = z.string().max(35, {
    message: t("formValidation.string.tooLong", {
      max: 35,
    }),
  })

  // Min 1 character
  const min = z.string().min(1, {
    message: t("formValidation.string.tooShort", {
      min: 1,
    }),
  })

  return onlyAllowedCharacters.and(max).and(min).and(noForbiddenFirstChar)
}

export const UuidSchema = (t: CommonTFunction) => {
  return z
    .string({ message: t("formValidation.common.invalid") })
    .min(1, { message: t("formValidation.common.invalid") })
}

export const StringSchema = (t: CommonTFunction, min = 1) => {
  return z
    .string({ message: t("formValidation.common.invalid") })
    .min(min, { message: t("formValidation.string.tooShort", { min }) })
}

export const PinSchema = (t: CommonTFunction) => {
  return z
    .string({ message: t("formValidation.common.invalid") })
    .min(6, { message: t("formValidation.string.tooShort", { min: 6 }) })
    .max(6, { message: t("formValidation.string.tooLong", { max: 6 }) })
    .regex(/^[0-9]*$/, { message: t("formValidation.pin.invalid") })
}

export const DateSchema = (t: CommonTFunction) => {
  return z.date({
    required_error: t("formValidation.common.requiredField"),
    invalid_type_error: t("formValidation.common.invalid"),
  })
}
