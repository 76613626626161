import { gql } from "@apollo/client"
import { use2faChallenge } from "../../../../Auth/Challenge2fa/use2faChallenge.hooks"

export function useApplyForCreditWireTransfer() {
  const { mutationWith2fa, mutationState } = use2faChallenge<
    { creditWireTransferApply: CWT_APPLY_RESPONSE },
    CWT_APPLY_INPUT
  >(CWT_APPLY_REQ)

  const apply = async (input: CWT_APPLY_INPUT) => {
    const result = await mutationWith2fa(input)
    return result.data?.creditWireTransferApply
  }

  return { apply, ...mutationState }
}

type CWT_APPLY_INPUT = {
  merchantId: string
  cwt: {
    amount: number
    debtorIBAN: string | null
    installmentCount: number
    label: string
    recipient: {
      beneficiaryId: string
      email: string
      emailEnabled: boolean
      phone: string
      siret: string
      headquartersCountry: string
    }
    document: {
      path: string
    }
    businessAccountId: string | null
  }
}

type CWT_APPLY_RESPONSE = {
  success: boolean
}

export type GET_CREDIT_WIRE_TRANSFER_DOCUMENT_UPLOAD_URL_RESPONSE = {
  creditWireTransferDocumentUploadUrl: {
    signedUrl: string
    path: string
  }
}

const CWT_APPLY_REQ = gql`
  mutation creditWireTransferApply($merchantId: String!, $cwt: CWTApplyInput!) {
    creditWireTransferApply(merchantId: $merchantId, cwt: $cwt) {
      ... on CWTApplyOutput {
        success
      }
      ... on GqlHeroError {
        errorCode
        message
      }
      ... on ValidationErrors {
        errorCode
        validationErrors {
          path
          validationError
        }
      }
    }
  }
`
