import { Breadcrumb, BreadcrumbItem, Separator, Spinner, Typography } from "@hero/krypton"
import { Link, useParams } from "react-router-dom"
import styled from "styled-components"
import { toEuros } from "../../../../../00_shared/utils/currency.converter"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { BNPLProductType, PaymentType } from "../../enums/PaymentCore.enum"
import { PaymentStatusBadge } from "../PaymentStatusBadge/PaymentStatusBadge"
import { PaymentInstallment, PaymentRefund, useGetPaymentBnpl } from "./PaymentDetails.request"
import { OptionsButton } from "./components/OptionsButton"
import { useCallback } from "react"
import { toLocalizedDate, toLocalizedDateTime } from "../../../../../00_shared/utils/date.converter"

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-left: 1px solid ${({ theme }) => theme.colors.grey.$300};
  display: flex;
  flex-direction: column;
`

const Header = styled.header`
  padding: 1.5rem 3rem 1rem 3rem;
`

const NotFoundTypography = styled(Typography)`
  padding: 1.5rem 3rem;
`

const HeaderMain = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.5rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

const PaymentDetailsCard = styled.div`
  margin: 1rem 1.5rem;
  padding: 1rem;
  width: 70%;
  max-width: 35rem;
  border-radius: 0.75rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
  box-shadow: ${({ theme }) => theme.shadows.light};
  flex-grow: 1;
`

const PaymentDetailsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
`

const PaymentDetailsHeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`

const PaymentDetailsHeaderRight = styled.div`
  display: flex;
  flex-direction: column;
`

const BadgeContainer = styled.div`
  margin-top: 0.5rem;
`

const PaymentDetailCardSection = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
`

const PaymentDetailCardSectionLine = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`

const getTKeyPaymentKind = (paymentType: PaymentType, installmentCount: number, daysBeforeDueDate: number) => {
  if (paymentType === PaymentType.PAY_1X) {
    return "collections.paymentDetails.financing.noFinancing"
  }

  if (paymentType === PaymentType.PAY_ND) {
    return `collections.paymentDetails.financing.paynd.${daysBeforeDueDate}`
  }

  return `collections.paymentDetails.financing.paynx.${installmentCount}`
}

export const PaymentDetailsScreen = () => {
  const { id } = useParams<{
    id: string
  }>()

  const { t, lang } = useDashboardTranslation()

  console.log(lang)

  const { payment, loading, refetch } = useGetPaymentBnpl({ id })

  const getPaymentStatus = useCallback(
    (installment: PaymentInstallment) => {
      let status
      if (installment.paid) {
        status = "collections.paymentDetails.financing.installmentPaid"
      } else if (installment.refunded) {
        status = "collections.paymentDetails.financing.installmentRefunded"
      } else {
        status = "collections.paymentDetails.financing.installmentToPay"
      }

      return `${t(status)} ${toLocalizedDate(installment.refundedAt ?? installment.dueDate, lang)}`
    },
    [t, lang],
  )

  const getPaymentRefund = useCallback(
    (refund: PaymentRefund) => {
      if (!payment) {
        return
      }

      const refundType =
        refund.amount === payment.amount
          ? "collections.paymentDetails.refund.totalRefund"
          : "collections.paymentDetails.refund.partialRefund"

      return `${t(refundType)} ${toLocalizedDateTime(refund.createdAt, lang)}`
    },
    [payment, t, lang],
  )

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (!payment) {
    return (
      <Container>
        <NotFoundTypography $variant="body-2-bold">{t("collection.paymentDetails.notFound")}</NotFoundTypography>
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <HeaderMain>
          <Typography $variant="body-1-medium">{t("menu.list.collection")}</Typography>
          <OptionsButton payment={payment} refetch={refetch} />
        </HeaderMain>
        <Breadcrumb>
          <BreadcrumbItem<typeof Link> as={Link} to={`/collection/list?kind=${payment.productType.toLowerCase()}`}>
            {payment.productType === BNPLProductType.CHECKOUT
              ? t("collection.list.productCheckout")
              : t("collection.list.productLink")}
          </BreadcrumbItem>
          <BreadcrumbItem<"p"> as={"p"}>{payment.customerEmail}</BreadcrumbItem>
        </Breadcrumb>
      </Header>
      <Divider />
      <PaymentDetailsCard>
        <PaymentDetailsHeader>
          <PaymentDetailsHeaderLeft>
            <Typography $variant="title-3-semibold">{payment.customerName}</Typography>
            <BadgeContainer>
              <PaymentStatusBadge paymentStatus={payment.paymentStatus} />
            </BadgeContainer>
          </PaymentDetailsHeaderLeft>
          <PaymentDetailsHeaderRight>
            <Typography $variant="title-3-semibold">{toEuros(payment.amount)}</Typography>
          </PaymentDetailsHeaderRight>
        </PaymentDetailsHeader>
        <Divider />
        <PaymentDetailCardSection>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$600">
              {t("collections.paymentDetails.details")}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.customerName")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {payment.customerName}
            </Typography>
          </PaymentDetailCardSectionLine>
          {payment.customerSiret && (
            <PaymentDetailCardSectionLine>
              <Typography $variant="body-4-regular" $color="grey.$500">
                {t("collections.paymentDetails.customerSiret")}
              </Typography>
              <Typography $variant="body-4-medium" $color="grey.$600">
                {payment.customerSiret}
              </Typography>
            </PaymentDetailCardSectionLine>
          )}
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.customerPhone")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {payment.customerNormalizedContactPhone}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.customerEmail")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {payment.customerEmail}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.reference")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {payment.reference}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.accountToCredit")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {t("collections.paymentDetails.accountToCredit.label")}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.productType")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {payment.productType === BNPLProductType.CHECKOUT
                ? t("collection.list.productCheckout")
                : t("collection.list.productLink")}
            </Typography>
          </PaymentDetailCardSectionLine>
        </PaymentDetailCardSection>
        <Divider />
        <PaymentDetailCardSection>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$600">
              {t("collections.paymentDetails.fees")}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.heroFees")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {toEuros(payment.totalFee - payment.providerFees - payment.vat)}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.providerFees")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {toEuros(payment.providerFees)}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.vat")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {toEuros(payment.vat)}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.totalFee")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {toEuros(payment.totalFee)}
            </Typography>
          </PaymentDetailCardSectionLine>
        </PaymentDetailCardSection>
        <Divider />
        <PaymentDetailCardSection>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$600">
              {t("collections.paymentDetails.financing")}
            </Typography>
          </PaymentDetailCardSectionLine>
          <PaymentDetailCardSectionLine>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("collections.paymentDetails.financingType")}
            </Typography>
            <Typography $variant="body-4-medium" $color="grey.$600">
              {t(getTKeyPaymentKind(payment.paymentType, payment.installmentCount, payment.daysBeforeDueDate))}
            </Typography>
          </PaymentDetailCardSectionLine>
          {(payment.installments ?? []).map((installment) => (
            <PaymentDetailCardSectionLine key={installment.dueDate}>
              <Typography $variant="body-4-regular" $color="grey.$500">
                {getPaymentStatus(installment)}
              </Typography>
              <Typography $variant="body-4-medium" $color="grey.$600">
                {toEuros(Math.abs(installment.amount))}
              </Typography>
            </PaymentDetailCardSectionLine>
          ))}
        </PaymentDetailCardSection>
        {payment.refunds.length > 0 && (
          <>
            <Divider />
            <PaymentDetailCardSection>
              <PaymentDetailCardSectionLine>
                <Typography $variant="body-4-regular" $color="grey.$600">
                  {t("collections.paymentDetails.refund")}
                </Typography>
              </PaymentDetailCardSectionLine>
              {payment.refunds.map((refund) => (
                <PaymentDetailCardSectionLine key={refund.createdAt}>
                  <Typography $variant="body-4-regular" $color="grey.$500">
                    {getPaymentRefund(refund)}
                  </Typography>
                  <Typography $variant="body-4-medium" $color="grey.$600">
                    {toEuros(Math.abs(refund.amount))}
                  </Typography>
                </PaymentDetailCardSectionLine>
              ))}
            </PaymentDetailCardSection>
          </>
        )}
      </PaymentDetailsCard>
    </Container>
  )
}
