import { ErrorBlock } from "@hero/krypton"
import { useRouteError } from "react-router-dom"
import { HomeScreen } from "./Home.screen"

export const apRegulatedRoute = {
  path: "accelerated-payout",
  element: <HomeScreen />,
  errorElement: <ErrorBoundary />,
}

function ErrorBoundary() {
  const error = useRouteError()
  console.error(error)
  return (
    // eslint-disable-next-line i18next/no-literal-string
    <ErrorBlock $margin="2rem">
      Error when rendering your page. You can try to refresh the page. If the error persists, please contact us.
    </ErrorBlock>
  )
}
