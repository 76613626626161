import { Separator, Typography } from "@hero/krypton"
import styled from "styled-components"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../../00_shared/components/Sheet"
import { Spacer } from "../../../00_shared/components/Spacer"
import { centsToEuros, i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import {
  DateStyle,
  isDifferentDay,
  toInternationalDate,
  toInternationalDatetime,
} from "../../../00_shared/utils/date.converter"
import { formatAsPercentage, safeFloatToPercentage } from "../../../00_shared/utils/percentage.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { ApRegulatedStatusEnum } from "../hooks/getAcceleratedPayoutList"
import { StatusBadge } from "./StatusBadge"
import { Timeline } from "../../../00_shared/components/krypton2/Timeline"

enum ApHistoryTypeEnum {
  INIT = "INIT",
  UNLOCKING_FUNDS = "UNLOCKING_FUNDS",
  TAKE_FEE = "TAKE_FEE",
  TRANSFER_FUNDS_TO_BA = "TRANSFER_FUNDS_TO_BA",
  DEBT_REPAYMENT = "DEBT_REPAYMENT",
  DEBT_TOTALLY_REPAID = "DEBT_TOTALLY_REPAID",
  CANCELED = "CANCELED",
}

export const AcceleratedPayoutDetails = ({
  open,
  setDisplayedDetailsId,
}: {
  open: boolean
  setDisplayedDetailsId: (id: string) => void
}) => {
  const { t, lang } = useDashboardTranslation()
  const getTranslationForHistoryType = useGetTranslationForHistoryType()

  const details = {
    amount: 2000_78,
    periodStartAt: "2021-09-01T00:00:00Z",
    periodEndAt: "2021-09-30T23:59:59Z",
    status: ApRegulatedStatusEnum.TOTALLY_REPAID,
    periodRevenues: 1000_78,
    financingRate: 0.005,
    feeRate: 0.001,
    feeAmount: 20_78,
    reference: "#123456789",
    createdAt: "2021-09-01T00:00:00Z",
    lastReconciliationDate: "2021-09-30T23:59:59Z",
    history: [
      {
        type: ApHistoryTypeEnum.INIT,
        date: "2021-09-01T00:00:00Z",
      },
      {
        type: ApHistoryTypeEnum.UNLOCKING_FUNDS,
        amount: 2000_78,
        date: "2021-09-01T00:00:00Z",
      },
      {
        type: ApHistoryTypeEnum.TAKE_FEE,
        amount: 20_78,
        date: "2021-09-01T00:00:00Z",
      },
      {
        type: ApHistoryTypeEnum.TRANSFER_FUNDS_TO_BA,
        amount: 1980_00,
        date: "2021-09-01T00:00:00Z",
      },
      {
        type: ApHistoryTypeEnum.DEBT_REPAYMENT,
        amount: 1000_78,
        date: "2021-09-30T23:59:59Z",
      },
      {
        type: ApHistoryTypeEnum.DEBT_REPAYMENT,
        amount: 10000_0,
        date: "2021-09-30T23:59:59Z",
      },
      {
        type: ApHistoryTypeEnum.DEBT_TOTALLY_REPAID,
        date: "2021-09-30T23:59:59Z",
      },
    ],
  }

  return (
    <Sheet
      open={open}
      onOpenChange={(open) => {
        if (!open) {
          setDisplayedDetailsId("")
        }
      }}
    >
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>{t("apRegulated.dashboard.apDetails.title")}</SheetTitle>
        </SheetHeader>

        <Header>
          <div>
            <Typography $variant="title-3-inter-bold">
              {i18nCurrencyFormatter(centsToEuros(details.amount), lang)}
            </Typography>
            <Typography $variant="body-4-regular">
              {toInternationalDate({
                date: details.periodStartAt,
                language: lang,
                style: DateStyle.TEXTUAL,
              })}
              {isDifferentDay(details.periodStartAt, details.periodEndAt) && (
                <>
                  {" "}
                  -{" "}
                  {toInternationalDate({
                    date: details.periodEndAt,
                    language: lang,
                    style: DateStyle.TEXTUAL,
                  })}
                </>
              )}
            </Typography>
          </div>
          <div>
            <StatusBadge status={details.status}></StatusBadge>
          </div>
        </Header>

        <Spacer $height="2.5rem" />

        <Timeline
          timeline={details.history.map((history) => {
            const label = getTranslationForHistoryType(history.type)

            return {
              title: label(history.amount) || history.type,
              subtitle: toInternationalDatetime({
                date: history.date,
                language: lang,
                style: DateStyle.TEXTUAL,
              }),
            }
          })}
        />

        <Spacer $height="2.5rem" />

        <Separator />

        <Spacer $height="2rem" />

        <Typography $variant="body-4-medium">{t("apRegulated.dashboard.apDetails.details")}</Typography>

        <Spacer $height=".5rem" />

        <Details>
          <DetailRow>
            <DetailLabel>{t("apRegulated.dashboard.apDetails.periodRevenues")}</DetailLabel>
            <DetailValue>{i18nCurrencyFormatter(centsToEuros(details.periodRevenues), lang)}</DetailValue>
          </DetailRow>

          <DetailRow>
            <DetailLabel>{t("apRegulated.dashboard.apDetails.financingRate")}</DetailLabel>
            <DetailValue>{formatAsPercentage(safeFloatToPercentage(details.financingRate))}</DetailValue>
          </DetailRow>

          <DetailRow>
            <DetailLabel>{t("apRegulated.dashboard.apDetails.feeRate")}</DetailLabel>
            <DetailValue>{formatAsPercentage(safeFloatToPercentage(details.feeRate))}</DetailValue>
          </DetailRow>

          <DetailRow>
            <DetailLabel>{t("apRegulated.dashboard.apDetails.feeAmount")}</DetailLabel>
            <DetailValue>{i18nCurrencyFormatter(centsToEuros(details.feeAmount), lang)}</DetailValue>
          </DetailRow>

          <DetailRow>
            <DetailLabel>{t("apRegulated.dashboard.apDetails.reference")}</DetailLabel>
            <DetailValue>{details.reference}</DetailValue>
          </DetailRow>
        </Details>
      </SheetContent>
    </Sheet>
  )
}

const useGetTranslationForHistoryType = () => {
  const { t, lang } = useDashboardTranslation()

  const formatAmount = (amount?: number) => (amount ? i18nCurrencyFormatter(centsToEuros(amount), lang) : "NO_AMOUNT")

  return (type: string): ((amount?: number) => string | null) => {
    if (type === ApHistoryTypeEnum.INIT) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.init", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.UNLOCKING_FUNDS) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.unlockingFunds", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.TAKE_FEE) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.takeFee", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.TRANSFER_FUNDS_TO_BA) {
      return (amount) =>
        t("apRegulated.dashboard.apDetails.history.transferFundsToBa", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.DEBT_REPAYMENT) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.debtRepayment", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.DEBT_TOTALLY_REPAID) {
      return (amount) =>
        t("apRegulated.dashboard.apDetails.history.debtTotallyRepaid", { amount: formatAmount(amount) })
    }

    if (type === ApHistoryTypeEnum.CANCELED) {
      return (amount) => t("apRegulated.dashboard.apDetails.history.canceled", { amount: formatAmount(amount) })
    }

    return () => null
  }
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const DetailRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailLabel = styled(Typography).attrs({ $variant: "body-4-regular" })`
  color: ${({ theme }) => theme.colors.grey.$500};
  margin-right: 1rem;
`

const DetailValue = styled(Typography).attrs({ $variant: "body-4-medium" })`
  color: ${({ theme }) => theme.colors.grey.$600};
`
