import { Connect } from "../../01_technical/client"
import Challenge2faProvider from "../../Auth/Challenge2fa/Challenge2fa.context"
import Setup2faProvider from "../../Auth/Setup2fa/Setup2fa.context"

interface All2faProvidersProps {
  children: JSX.Element
}

const All2faProviders = ({ children }: All2faProvidersProps) => {
  return (
    <Connect>
      <Challenge2faProvider>
        <Setup2faProvider>{children}</Setup2faProvider>
      </Challenge2faProvider>
    </Connect>
  )
}

export default All2faProviders
