import { Typography } from "@hero/krypton"
import { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useRouteListener } from "../../../00_shared/hooks/useRouteListener"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { useAuthContext } from "../../../Auth/auth.context"

const Banner = styled.div<{ $isFixedFullWidth?: boolean }>`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.info.$100};
  position: ${({ $isFixedFullWidth }) => ($isFixedFullWidth ? "fixed" : "sticky")};
  left: 0px;
  top: 0px;
  padding: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  border: 2px solid ${({ theme }) => theme.colors.info.$300};
`

export const ImpersonateBanner = () => {
  const { currentUser, setImpersonateBannerHeight } = useAuthContext()
  const { t } = useDashboardTranslation()

  const bannerRef = useRef<HTMLDivElement>(null)
  const [isFixedFullWidth, setIsFixedFullWidth] = useState(false)

  const handleRouteChange = (pathname: string) => {
    if (pathname.startsWith("/transfers/create")) {
      setIsFixedFullWidth(true)
      return
    }

    if (pathname.startsWith("/accounts/create")) {
      setIsFixedFullWidth(true)
      return
    }
    if (pathname.startsWith("/cards/create")) {
      setIsFixedFullWidth(true)
      return
    }

    setIsFixedFullWidth(false)
  }

  useRouteListener(handleRouteChange)

  useEffect(() => {
    if (!bannerRef.current) return

    const resizeObserver = new ResizeObserver(() => {
      const height = currentUser.isUserImpersonated ? bannerRef.current?.offsetHeight || 0 : 0
      setImpersonateBannerHeight(height)
    })

    resizeObserver.observe(bannerRef.current)
    return () => resizeObserver.disconnect()
  }, [currentUser.isUserImpersonated, setImpersonateBannerHeight])

  return (
    <>
      {currentUser.isUserImpersonated && (
        <Banner ref={bannerRef} $isFixedFullWidth={isFixedFullWidth}>
          <Typography $color="info.$300" $variant="body-3-regular">
            {t("impersonate.banner.label")}
          </Typography>
        </Banner>
      )}
    </>
  )
}
