import { useState } from "react"
import { AccountingData, useLazyGetAccountingExportData } from "./getAccountingExportData.hook"
import { generateSpreadsheet } from "../../../00_shared/utils/sheetjs.utils"
import { DashboardTFunction, useDashboardTranslation } from "../../../01_technical/translations"
import { toaster } from "@hero/krypton"
import { toEuros } from "../../../00_shared/utils/currency.converter"

export const useRegulatedApExport = () => {
  const getAccountingExportData = useLazyGetAccountingExportData()
  const { t } = useDashboardTranslation()

  const [exporting, setExporting] = useState(false)

  const exportAccounting = async (
    ledgers: { id: string; name: string }[],
    documentFormat: "xlsx" | "ods",
    filters: {
      fromDatetime: Date
      toDatetime: Date
    },
  ) => {
    try {
      setExporting(true)
      const requests = await Promise.all(
        ledgers.map(async (ledger) => {
          const accountingExportData = await getAccountingExportData({
            ledgerId: ledger.id,
            fromDatetime: filters.fromDatetime.toISOString(),
            toDatetime: filters.toDatetime.toISOString(),
          })

          const formattedAccountingExportData = format(accountingExportData, t)

          return {
            sheetName: ledger.name,
            jsonData: formattedAccountingExportData,
          }
        }),
      )
      generateSpreadsheet(requests, "accelerated-payout-accounting-export", documentFormat)
    } catch {
      toaster.error(t("apRegulated.accountingExport.exportError"))
    } finally {
      setExporting(false)
    }
  }

  return {
    exportAccounting,
    exporting,
  }
}

const format = (data: AccountingData[], t: DashboardTFunction) => {
  const formatAmount = (amount?: string) => {
    return amount ? toEuros(parseInt(amount, 10)) : ""
  }

  return data.map((accountingData) => ({
    [t("apRegulated.accountingExport.createdAt")]: accountingData.createdAt,
    [t("apRegulated.accountingExport.ledgerAccountName")]: accountingData.ledgerAccountName,
    [t("apRegulated.accountingExport.ledgerAccountNumber")]: accountingData.ledgerAccountNumber,
    [t("apRegulated.accountingExport.businessType")]: accountingData.businessType,
    [t("apRegulated.accountingExport.ledgerMovementId")]: accountingData.ledgerMovementId,
    [t("apRegulated.accountingExport.currency")]: accountingData.currency,
    [t("apRegulated.accountingExport.amount")]: formatAmount(accountingData.amount),
    [t("apRegulated.accountingExport.apPeriodRevenue")]: formatAmount(accountingData.apPeriodRevenue),
    [t("apRegulated.accountingExport.apOrders")]: accountingData.apOrders,
    [t("apRegulated.accountingExport.apFinancedAmount")]: formatAmount(accountingData.apFinancedAmount),
    [t("apRegulated.accountingExport.apFeeAmount")]: formatAmount(accountingData.apFeeAmount),
    [t("apRegulated.accountingExport.apPeriodStartAt")]: accountingData.apPeriodStartAt,
    [t("apRegulated.accountingExport.apPeriodEndAt")]: accountingData.apPeriodEndAt,
    [t("apRegulated.accountingExport.apId")]: accountingData.apId,
  }))
}
