import { gql } from "@apollo/client"
import { useHeroMutation } from "../../../../../01_technical/requesting/useHeroMutation/useHeroMutation"
import { IssuanceType, IssuingCard } from "../../../00_shared/business/IssuingCard"

const CREATE_CARD = gql`
  mutation CreateCard($businessAccountId: String!, $cardholderId: String!, $cardDetails: CardDetailsInput!) {
    createCard(businessAccountId: $businessAccountId, cardholderId: $cardholderId, cardDetails: $cardDetails) {
      ... on IssuingCard {
        id
        status
        consumedMonthlySpendingLimitAmountEuros
        paymentAccountName
        pending3dsChallengeId
        formattedOrderedDate
        formattedProductionDate
        formattedReceptionDate
        isDeliveryLate
        notReceivedDeclarationDate
      }
      ... on ValidationErrors {
        validationErrors {
          path
          validationError
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

// TO DO: Store in DB and fetch with API ?
export enum EnabledDeliveryCountryCodes {
  FR = "FR",
  ES = "ES",
  IT = "IT",
}

interface IssuingCardAddressInput {
  address1: string
  address2?: string
  address3?: string
  address4?: string
  zipCode: string
  city: string
  region?: string
  country: string
}

interface CardDetailsInput {
  cardType: IssuanceType
  address?: IssuingCardAddressInput
}

export interface CreateCardMutationResult {
  id: string
  cardholderId: string
  cardType: IssuanceType
  status: IssuingCard["status"]
  consumedMonthlySpendingLimitAmountEuros: string
  paymentAccountName: string
  pending3dsChallengeId: string | null
  formattedOrderedDate: string
  formattedProductionDate: string
  formattedReceptionDate: string
  isDeliveryLate: boolean
  notReceivedDeclarationDate: string | null
}

interface CreateCardMutationVariables {
  businessAccountId: string
  cardholderId: string
  cardDetails: CardDetailsInput
}

export const useCreateCard = () => {
  const [mutate, { data, loading, error }] = useHeroMutation<CreateCardMutationResult, CreateCardMutationVariables>({
    gqlQuerySchema: CREATE_CARD,
    translations: {},
  })

  const createCard = async (businessAccountId: string, cardholderId: string, cardDetails: CardDetailsInput) => {
    try {
      const response = await mutate({
        variables: {
          businessAccountId,
          cardholderId,
          cardDetails,
        },
      })

      return response
    } catch (err) {}
  }

  return {
    createCard,
    data,
    loading,
    error,
  }
}
