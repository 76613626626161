import styled from "styled-components"
import { useEffect, useState } from "react"
import { isMobile, isIOS, isAndroid } from "react-device-detect"
import appLogo from "../../../assets/hero-app.png"
import { CloseStrokeIcon, ArrowRightIcon, Typography } from "@hero/krypton"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { APPLE_STORE_URL, GOOGLE_PLAY_URL } from "../../../env_variables"

const Dialog = styled.div<{ $isOpen: boolean }>`
  position: sticky;
  top: ${({ $isOpen }) => ($isOpen ? 0 : "-5rem")};
  z-index: 1;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: ${({ theme }) => theme.shadows.strong};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  padding: 1rem;
  height: 5rem;
  transition:
    margin-top,
    top 0.2s ease-in-out;
  align-items: ${({ $isOpen }) => ($isOpen ? "center" : "inherit")};
  margin-top: ${({ $isOpen }) => ($isOpen ? 0 : "-5rem")};
`

const Row = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
`

const AppLogo = styled.img`
  width: 3rem;
  border-radius: 22.5%;
  margin-left: 0.5rem;
  height: auto;
`

const Redirect = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`

const AnimatedIcon = styled.div`
  animation: bounceWithDelay 3s infinite;

  @keyframes bounceWithDelay {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(10px);
    }
    60% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
  }
`

export const MobileRedirect = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useDashboardTranslation()

  useEffect(() => {
    if (isMobile) {
      setIsOpen(true)
    }
  }, [])

  useEffect(() => {
    const resize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false)
      }
    }

    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [isOpen])

  const getAppUrl = () => {
    if (isIOS) {
      return APPLE_STORE_URL
    }
    if (isAndroid) {
      return GOOGLE_PLAY_URL
    }
  }

  if (!isMobile) {
    return null
  }

  return (
    <Dialog $isOpen={isOpen}>
      <Row>
        <CloseStrokeIcon onClick={() => setIsOpen(false)} />
        <Container>
          <AppLogo src={appLogo} alt="" />
          <Redirect href={getAppUrl()}>
            <Typography $variant="body-4-medium">{t("mobileDialog.redirect")}</Typography>
            <AnimatedIcon>
              <ArrowRightIcon />
            </AnimatedIcon>
          </Redirect>
        </Container>
      </Row>
    </Dialog>
  )
}
