import { gql } from "@apollo/client"
import { useHeroQuery } from "../../../01_technical/requesting/useHeroQuery/useHeroQuery"

const GQL = gql`
  query getInfosForAccountingExport {
    getInfosForAccountingExport {
      ... on GetInfosForAccountingExportOutput {
        ledgerAccounts {
          id
          name
          type
        }
      }
      ... on GqlHeroError {
        errorCode
      }
      ... on ValidationErrors {
        errorCode
      }
    }
  }
`

export const useGetInfosForAccountingExport = () => {
  return useHeroQuery<{
    ledgerAccounts: { id: string; name: string; type: "AP_LEDGER_ACCOUNT" | "AP_HOLD_LEDGER_ACCOUNT" }[]
  }>({
    gqlQuerySchema: GQL,
    fetchPolicy: "no-cache",
  })
}
