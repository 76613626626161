import { Button, ErrorBlock, SwitchIcon, Typography } from "@hero/krypton"
import styled from "styled-components"
import CenteredLoading from "../../../00_shared/components/CenteredLoading"
import { Spacer } from "../../../00_shared/components/Spacer"
import { i18nCurrencyFormatter } from "../../../00_shared/utils/currency.converter"
import { toLocalizedDate } from "../../../00_shared/utils/date.converter"
import { useDashboardTranslation } from "../../../01_technical/translations"
import {
  DashboardSectionHeader as Header,
  DashboardSectionHeaderIconWrapper as HeaderIconWrapper,
  DashboardSectionTitle as HeaderTitle,
  DashboardSection as Section,
} from "../../../00_shared/components/krypton2/DashboardSection"
import {
  MarketplaceTransactionOverviewItem,
  useGetMarketplaceTransactionOverview,
} from "../hooks/getMarketplaceTransactionOverview"

export const MarketplaceTransfersOverview = () => {
  const { t } = useDashboardTranslation()
  const { data, loading, error } = useGetMarketplaceTransactionOverview()

  return (
    <Section isSticky>
      <Header>
        <HeaderTitle>
          <HeaderIconWrapper>
            <SwitchIcon />
          </HeaderIconWrapper>
          {t("apRegulated.dashboard.marketplaceTransferOverview.title")}
        </HeaderTitle>

        <Spacer $height=".25rem" />

        <Typography>{t("apRegulated.dashboard.marketplaceTransferOverview.subtitle")}</Typography>
      </Header>

      <Spacer $height="1rem" />

      {loading && (
        <>
          <Spacer $height="1.5rem" />
          <CenteredLoading />
          <Spacer $height="1.5rem" />
        </>
      )}

      {error && <ErrorBlock $margin="0">{error.translatedMessage}</ErrorBlock>}

      {data && (
        <>
          <CardList>
            {data.map((item) => (
              <Card key={item.transactionId} {...item} />
            ))}
          </CardList>

          <Spacer $height="1rem" />

          <Button
            $fullWidth
            $variant="tertiary"
            size="medium"
            type="button"
            onClick={() => {
              alert("not implemented yet")
            }}
          >
            {t("apRegulated.dashboard.marketplaceTransferOverview.seeAll")}
          </Button>
        </>
      )}
    </Section>
  )
}

const Card = (props: MarketplaceTransactionOverviewItem) => {
  const { lang, t } = useDashboardTranslation()

  return (
    <CardRelative>
      <CardWrapper>
        <Icon src={props.marketplace.logoUrl}></Icon>
        <CardText>
          <Typography $variant="caption-2">{toLocalizedDate(props.date, lang)}</Typography>
          {props.nbOfReconciledAp > 0 && (
            <Typography $variant="caption-2" style={{ color: "#696969" }}>
              {props.nbOfReconciledAp === 1 && (
                <>
                  {t("apRegulated.dashboard.marketplaceTransferOverview.oneApClosed", {
                    nbOfReconciledAp: props.nbOfReconciledAp,
                  })}
                </>
              )}

              {props.nbOfReconciledAp > 1 && (
                <>
                  {t("apRegulated.dashboard.marketplaceTransferOverview.multipleApsClosed", {
                    nbOfReconciledAp: props.nbOfReconciledAp,
                  })}
                </>
              )}
            </Typography>
          )}
        </CardText>
        <Typography $variant="body-4-semibold">{i18nCurrencyFormatter(props.amount, lang)}</Typography>
      </CardWrapper>
      <MultiCardEffect isActivated={props.nbOfReconciledAp > 0} />
    </CardRelative>
  )
}

const CardText = styled.div`
  flex: 1;
`

const CardRelative = styled.div`
  position: relative;
`

const CardWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
`

const MultiCardEffect = styled.div<{ isActivated: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: ${({ isActivated }) => (isActivated ? "-.25rem" : "0")};
  z-index: 1;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.light};
`

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Icon = styled.img`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border-color: ${({ theme }) => theme.colors.grey.$200};
  border-width: 1px;
  border-style: solid;
`
