import { gql } from "@apollo/client"

export type SigninArgs = {
  email: string
  password: string
  platform: "desktop" | "mobile"
}

export type SigninSuccess = {
  signinDashboard: {
    token: string
  }
}

type SigninChallengeFunctionalError = {
  signinDashboard: {
    errorCode: string
    errorValue: string
  }
}

export type SigninFunctionalError = {
  signinDashboard: {
    errorCode: string
    message: string
    detail: string
  }
}

export type SigninResponse = SigninSuccess | SigninFunctionalError | SigninChallengeFunctionalError

export const SIGNIN_DASHBOARD_REQUEST = gql`
  mutation SigninDashboard($email: String!, $password: String!) {
    signinDashboard(user: { email: $email, password: $password }) {
      ... on SigninOutput {
        token
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
    }
  }
`
type Setup2faSuccess = {
  check2FAEnrollChallenge: {
    status: string
  }
}
export type Setup2faError = {
  check2FAEnrollChallenge: {
    errorCode: string
  }
}

export type Setup2faResponse = Setup2faSuccess | Setup2faError

export type AutologinArgs = {
  email: string
  autologinToken: string
  platform: string
}

export type AutologinSuccess = {
  autologin: {
    token: string
  }
}

type AutologinFunctionalError = {
  autologin: {
    errorCode: string
    errorValue: string
  }
}

export type Autologin2faFunctionalError = {
  autologin: {
    errorCode: string
    message: string
    detail: string
  }
}

export type AutologinResponse = AutologinSuccess | AutologinFunctionalError | Autologin2faFunctionalError

export const AUTOLOGIN_DASHBOARD_REQUEST = gql`
  mutation autologin($email: String!, $autologinToken: String!, $platform: String) {
    autologin(email: $email, autologinToken: $autologinToken, platform: $platform) {
      ... on SigninOutput {
        token
      }
      ... on SimpleApiError {
        errorCode
      }
      ... on GqlHeroError {
        errorCode
        message
        detail
      }
    }
  }
`

export function convertAutologin2faErrorToSignin2faError(error: Autologin2faFunctionalError): SigninFunctionalError {
  return {
    signinDashboard: {
      errorCode: error.autologin.errorCode,
      message: error.autologin.message,
      detail: error.autologin.detail,
    },
  }
}
