import { useApolloClient } from "@apollo/client"
import { Navigate, useSearchParams } from "react-router-dom"
import { LEGACY_SIGNIN, ONBOARDING_LOGOUT_URL } from "../../env_variables"
import { Spinner } from "@hero/krypton"

export const Logout = () => {
  const [queryParams] = useSearchParams()

  const client = useApolloClient()
  client.clearStore()

  localStorage.removeItem("token")
  localStorage.removeItem("BO_V3")

  if (LEGACY_SIGNIN) {
    return <Navigate to="/" />
  }

  window.location.href = queryParams.get("path")
    ? `${ONBOARDING_LOGOUT_URL}?path=${queryParams.get("path")}`
    : ONBOARDING_LOGOUT_URL
  return <Spinner />
}
