// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const API_URI = process.env.REACT_APP_API_URL!

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const PAY_URI = process.env.REACT_APP_PAY_URL!

export const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || "unknown"

export const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_ENABLED === "true"

export const HEADER_COLORS: "default" | "info" | "warning" | "danger" =
  (process.env.REACT_APP_HEADER_COLOR as "default" | "info" | "warning" | "danger" | undefined) ?? "default"

export const DEMO_MERCHANT_ID = process.env.REACT_APP_DEMO_MERCHANT_ID || "unknown"
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "unknown"
export const INTERCOM_ENABLED = process.env.REACT_APP_INTERCOM_ENABLED === "true"
export const SWAN_CLIENT_ID = process.env.REACT_APP_SWAN_CLIENT_ID
export const SWAN_AUTH_URL = process.env.REACT_APP_SWAN_AUTH_URL
export const SWAN_REDIRECT_URL = process.env.REACT_APP_SWAN_REDIRECT_URL
export const SWAN_SETUP_REDIRECT_URL = process.env.REACT_APP_SWAN_SETUP_REDIRECT_URL

export const ISSUING_PAN_KEY = process.env.REACT_APP_ISSUING_PAN_KEY

export const HOTJAR_HJID = Number(process.env.REACT_APP_HOTJAR_HJID)
export const HOTJAR_HJSV = Number(process.env.REACT_APP_HOTJAR_HJSV)

export const LEGACY_SIGNIN = process.env.REACT_APP_LEGACY_SIGNIN === "true"
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ONBOARDING_SIGNIN_URL = process.env.REACT_APP_ONBOARDING_SIGNIN_URL!
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const ONBOARDING_LOGOUT_URL = process.env.REACT_APP_ONBOARDING_LOGOUT_URL!

export const APPLE_STORE_URL = process.env.REACT_APP_APPLE_STORE_URL
export const GOOGLE_PLAY_URL = process.env.REACT_APP_GOOGLE_PLAY_URL

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const HERO_PRODUCT_ONBOARDING_URL = process.env.REACT_APP_HERO_PRODUCT_ONBOARDING_URL!

export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
