import { Button, Field, InfoIcon, Modal, Separator, toaster, Tooltip, Typography } from "@hero/krypton"
import { FC, useCallback, useMemo } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { centsToEuros, currencyFormatter, eurosToCents } from "../../../../../00_shared/utils/currency.converter"
import { HeroApiError } from "../../../../../01_technical/requesting/request-error.type"
import { useDashboardTranslation } from "../../../../../01_technical/translations"
import { Payment } from "../../../List/List.requests"
import { BNPLProductType, PaymentType } from "../../enums/PaymentCore.enum"
import { useRefundPaymentCheckout } from "./RefundPaymentCheckout.requests"
import { useRefundPaymentLink } from "./RefundPaymentLink.requests"

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
`

const Content = styled.main`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`

const Divider = styled(Separator)`
  height: 1px;
`

const Description = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

const SectionTitle = styled(Typography)`
  color: ${(props) => props.theme.colors.grey.$500};
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
`

const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`

const StyledField = styled(Field)`
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

export const RefundModal: FC<{
  payment?: Payment
  isOpen: boolean
  onClose: () => void
  refetch: () => void
}> = ({ payment, isOpen, onClose, refetch }) => {
  const { t } = useDashboardTranslation()
  const { register, getValues, watch, resetField } = useForm<{ amount: string }>()
  const [refundPaymentLink, { loading: isRefundPaymentLinkLoading }] = useRefundPaymentLink()
  const [refundPaymentCheckout, { loading: isRefundPaymentCheckoutLoading }] = useRefundPaymentCheckout()

  const translatedErrors: Record<string, string | undefined> = useMemo(
    () => ({
      AMOUNT_TOO_HIGH: t("collection.modal.refund.errors.amountTooHigh"),
    }),
    [t],
  )

  const handleSubmit = useCallback(async () => {
    if (!payment) {
      return
    }

    const { amount } = getValues()

    try {
      if (payment.productType === BNPLProductType.CHECKOUT) {
        await refundPaymentCheckout({
          variables: {
            input: {
              amount: eurosToCents(Number(amount)),
              paymentCheckoutId: payment.id,
            },
          },
        })
      } else if (payment.productType === BNPLProductType.LINK) {
        await refundPaymentLink({
          variables: {
            input: {
              amount: eurosToCents(Number(amount)),
              paymentLinkId: payment.id,
            },
          },
        })
      } else {
        throw new Error("Invalid product type")
      }

      toaster.success(t("collection.modal.refund.success"))
      resetField("amount")
      onClose()
      refetch()
    } catch (e: unknown) {
      const error = e as HeroApiError
      toaster.error(translatedErrors[error.message] ?? error.translatedMessage ?? error.message)
    }
  }, [payment, getValues, t, resetField, onClose, refetch, refundPaymentCheckout, refundPaymentLink, translatedErrors])

  const amount = watch("amount")

  const refundedAmount = useMemo(
    () => payment?.refunds.reduce((acc, refund) => (refund.status !== "FAILED" ? acc + refund.amount : acc), 0) ?? 0,
    [payment],
  )

  const isDisabled = useMemo(() => {
    if (!payment) {
      return true
    }

    const amountNumber = Number(amount)
    const refundableAmount = payment.amount - refundedAmount

    return isNaN(amountNumber) || amountNumber <= 0 || amountNumber > centsToEuros(refundableAmount)
  }, [refundedAmount, amount, payment])

  if (!payment) {
    return null
  }

  const paymentTypeLabel =
    payment.paymentType === PaymentType.PAY_1X
      ? t("collection.list.types.1x")
      : payment.paymentType === PaymentType.PAY_ND
        ? t("collection.list.types.nd", { days: payment.daysBeforeDueDate })
        : t("collection.list.types.nx", { installmentCount: payment.installmentCount })

  const isLoading = isRefundPaymentLinkLoading || isRefundPaymentCheckoutLoading
  const remainingAmount = payment.amount - refundedAmount

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Content>
        <Header>
          <Typography $variant="title-1-bold">{t("collection.modal.refund.title")}</Typography>
          <Description $variant="body-4-regular">{t("collection.modal.refund.subtitle")}</Description>
        </Header>
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.amount")}</SectionTitle>
          <Typography $variant="body-4-medium">{currencyFormatter(centsToEuros(payment.amount))}</Typography>
        </Section>
        {refundedAmount > 0 && (
          <>
            <Divider />
            <Section>
              <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.refundedAmount")}</SectionTitle>
              <Typography $variant="body-4-medium">{currencyFormatter(centsToEuros(refundedAmount))}</Typography>
            </Section>
          </>
        )}
        <Divider />
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.type")}</SectionTitle>
          <Typography $variant="caption-1-bold">{paymentTypeLabel ?? "-"}</Typography>
        </Section>
        <Divider />
        <Section>
          <SectionTitle $variant="body-4-regular">{t("collection.modal.refund.customer")}</SectionTitle>
          <Typography $variant="body-4-medium">{payment.customerEmail}</Typography>
        </Section>
        <Divider />

        <FormHeader>
          <Typography $variant="body-4-medium">{t("collection.modal.refund.field")}</Typography>
          <Tooltip content={t("collection.modal.refund.tooltip")} id={`refund-${payment.id}`} position="top-left">
            <InfoIcon />
          </Tooltip>
        </FormHeader>

        <StyledField
          type="number"
          placeholder={centsToEuros(payment.amount - refundedAmount).toString()}
          step="1"
          aria-invalid
          {...register("amount")}
        />

        <ButtonContainer>
          <Button $variant="primary" onClick={handleSubmit} isLoading={isLoading} disabled={isDisabled}>
            {t("collection.modal.refund.field")}
          </Button>

          {eurosToCents(Number(amount)) > remainingAmount && (
            <Typography $variant="caption-2" $color="danger.$200">
              {t("collection.modal.refund.maximumRefundableAmount", {
                amount: currencyFormatter(centsToEuros(payment.amount - refundedAmount)),
              })}
            </Typography>
          )}
        </ButtonContainer>
      </Content>
    </Modal>
  )
}
