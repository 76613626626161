import { EnabledDeliveryCountryCodes } from "./hooks/useCreateCard.hook"

/**
 * Get the displayed address based on the country
 * Ex: "45 avenue de Clichy" vs. "Carrera de Tordera, 36"
 * @param streetNumber
 * @param routeName
 * @param countryCode
 */
export function formatDisplayedAddress(
  streetNumber: string,
  routeName: string,
  countryCode: EnabledDeliveryCountryCodes,
) {
  if (!streetNumber) {
    return routeName
  }

  if (countryCode === EnabledDeliveryCountryCodes.ES || countryCode === EnabledDeliveryCountryCodes.IT) {
    return `${routeName}, ${streetNumber}`
  }

  return `${streetNumber} ${routeName}`
}
