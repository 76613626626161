import { Button, FieldError, FieldInput, Label, theme, toaster, Typography } from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { z } from "zod"
import { Card, CardHeader, CardTitle } from "../../../../00_shared/components/Card"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import useMediaQuery from "../../../../00_shared/hooks/useMediaQuery.hook"
import { StrictBankingString } from "../../../../00_shared/utils/commonZodSchemas.utils"
import { CommonTFunction, useCommonTranslation } from "../../../../01_technical/translations"
import { useAuthContext } from "../../../../Auth/auth.context"
import CreateAccountIcon from "../../00_shared/icons/create-account-icon.png"
import { useBusinessAccountContext } from "../../businessAccount.context"
import { FormContainer as Form } from "../00_shared/components/ui/FormContainer"
import { useBusinessAccounts } from "../00_shared/hooks/useBusinessAccounts"
import { useCreateBusinessAccount } from "../00_shared/hooks/useCreateBusinessAccount"

const CardContainer = styled(Card)`
  border: none;
  box-shadow: none;
  width: 32rem;
  margin: 0 auto;
  padding-top: 10rem;

  @media (max-width: 768px) {
    width: 100%;
    padding-top: 4rem;
  }

  display: flex;
  flex-direction: column;
  gap: 2rem;
  > div {
    padding: 0;
  }
`

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
`

const AccountInput = styled(FieldInput)`
  width: 100%;
`

const HeaderSubTitle = styled(Typography).attrs(() => ({
  $variant: "body-3-regular",
}))`
  color: ${theme.colors.grey.$500};
`

const ContentSubTitle = styled(Typography).attrs(() => ({
  $variant: "body-3-regular",
}))`
  color: ${theme.colors.grey.$500};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: end;
  @media (min-width: 768px) {
    justify-content: start;
  }
`

const getCreateBusinessAccountValidator = (t: CommonTFunction) =>
  z.object({
    name: StrictBankingString(t),
  })

export const AccountCreate = () => {
  const navigate = useNavigate()
  const { t } = useCommonTranslation()
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { mutate, loading, error } = useCreateBusinessAccount()
  const { refetch } = useBusinessAccounts()
  const { isBaActive } = useBusinessAccountContext()
  const { impersonateBannerHeight } = useAuthContext()
  const createBusinessAccountValidator = getCreateBusinessAccountValidator(t)
  type CreateBusinessAccountFormValues = z.infer<typeof createBusinessAccountValidator>

  const preventAutoFocus = (event: Event) => {
    event.preventDefault()
  }

  const handleCloseOverlay = () => {
    navigate("..")
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateBusinessAccountFormValues>({
    resolver: zodResolver(createBusinessAccountValidator),
  })

  const onSubmit = async (data: CreateBusinessAccountFormValues) => {
    try {
      const account = await mutate({ variables: { name: data.name.trim() } })
      refetch()
      toaster.success(t("account.create.success"))
      navigate(`/accounts/${account.ledgerId}/transactions`)
    } catch (e) {
      toaster.error(t("account.create.error"))
    }
  }

  return (
    <Dialog open={true} onOpenChange={handleCloseOverlay}>
      <DialogContent
        onOpenAutoFocus={preventAutoFocus}
        $disableAnimations={true}
        width="100%"
        height="100%"
        topOffset={impersonateBannerHeight}
      >
        <ScrollArea>
          <CardContainer>
            <CardHeader>
              <img src={CreateAccountIcon} alt="Ajouter un compte" width={100} />
              <CardTitle size={isMobile ? "title-3-semibold" : "title-1-bold"}>
                {t("account.create.page.title")}
              </CardTitle>
              <HeaderSubTitle>{t("account.create.informations.headline")}</HeaderSubTitle>
            </CardHeader>
            <FormContainer onSubmit={handleSubmit(onSubmit)}>
              <ContentSubTitle>{t("account.create.informations.content")}</ContentSubTitle>
              <div>
                <Label>{t("account.create.field.name.label")}</Label>
                <AccountInput
                  label={t("account.create.field.name.label")}
                  placeholder={t("account.create.field.name.placeholder")}
                  {...register("name")}
                  disabled={!isBaActive}
                />
                <FieldError>{errors.name?.message || error?.translatedMessage || <>&nbsp;</>}</FieldError>
              </div>
              <ButtonContainer>
                <Button type="submit" $variant="primary" isLoading={loading} size="medium" disabled={!isBaActive}>
                  {t("account.create.action")}
                </Button>
              </ButtonContainer>
            </FormContainer>
          </CardContainer>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}
