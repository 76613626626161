import { Wizard } from "@hero/krypton"
import React from "react"
import { Outlet } from "react-router-dom"
import styled from "styled-components"
import { Dialog, DialogContent } from "../../../../00_shared/components/Dialog"
import { ScrollArea } from "../../../../00_shared/components/ScrollArea"
import { useAuthContext } from "../../../../Auth/auth.context"
import { useBusinessAccountContext } from "../../businessAccount.context"
import { CreateTransferProvider, useCreateTransferContext } from "./CreateTransferContext"

const Container = styled.div`
  height: 100%;
  width: 40rem;
  margin: auto;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const WizardWrapper: React.FC = () => {
  const { steps, clearState } = useCreateTransferContext()
  const { returnToCreateTransferOrigin } = useBusinessAccountContext()

  const handleQuit = () => {
    clearState()
    returnToCreateTransferOrigin()
  }

  return (
    <Wizard onQuit={handleQuit} steps={steps}>
      <ScrollArea height="calc(100% - 6rem)">
        <Container>
          <Outlet />
        </Container>
      </ScrollArea>
    </Wizard>
  )
}

export const CreateTransfer: React.FC = () => {
  const { impersonateBannerHeight } = useAuthContext()
  return (
    <Dialog open={true}>
      <DialogContent
        disableCloseButton={true}
        $disableAnimations={true}
        width="100%"
        height="100%"
        topOffset={impersonateBannerHeight}
      >
        <CreateTransferProvider>
          <WizardWrapper />
        </CreateTransferProvider>
      </DialogContent>
    </Dialog>
  )
}
