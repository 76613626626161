import { gql } from "@apollo/client"
import { useState } from "react"
import { useHeroQuery } from "../../../../../01_technical/requesting/useHeroQuery/useHeroQuery"
import { LedgerMovementFlow, MerchantOperation, OperationType } from "../../../00_shared/business"

const GET_MERCHANT_OPERATIONS = gql`
  query getMerchantOperations($pagination: GetMerchantOperationsPagination!, $filters: GetMerchantOperationsFilters) {
    getMerchantOperations(pagination: $pagination, filters: $filters) {
      ... on GetMerchantOperationsOutput {
        operations {
          id
          amount
          creditorId
          creditorName
          debtorId
          debtorName
          reference
          operationType
          flow
          createdAt
          ledgerType
          isExternalTransfer
          justificativePath
          ledgerMovementId
          isHeroTransfer
        }
        limit
        total
        hasNext
        currentPage
      }
      ... on GqlHeroError {
        errorCode
        message
      }
    }
  }
`

interface MerchantOperationsPagination {
  limit?: number
  page: number
}

interface MerchantOperationsPaginationResult {
  limit: number
  total: number
  hasNext: boolean
  currentPage: number
}

interface MerchantOperationsFilters {
  productLedgerIds?: string[]
  operationTypes?: OperationType[]
  flow?: LedgerMovementFlow[]
  amount?: {
    equal?: number
    greaterThan?: number
    lessThan?: number
  }
  date?: {
    from?: Date
    to?: Date
  }
}

interface MerchantOperationsVariables {
  pagination: MerchantOperationsPagination
  filters?: MerchantOperationsFilters
}

export const useMerchantOperations = (
  initialPagination: MerchantOperationsPagination = { page: 1, limit: 10 },
  initialFilters?: MerchantOperationsFilters,
) => {
  const [variables, setVariables] = useState<MerchantOperationsVariables>({
    pagination: initialPagination,
    filters: initialFilters,
  })

  const { data, loading, error, refetch } = useHeroQuery<
    { operations: MerchantOperation[] } & MerchantOperationsPaginationResult
  >({
    gqlQuerySchema: GET_MERCHANT_OPERATIONS,
    variables: {
      pagination: variables.pagination,
      filters: variables.filters,
    },
    fetchPolicy: "cache-and-network",
  })

  return {
    operations: data?.operations || [],
    pagination: {
      limit: data?.limit || 0,
      total: data?.total || 0,
      currentPage: data?.currentPage || 1,
      totalPages: Math.ceil((data?.total || 0) / (data?.limit || 1)),
      hasNext: data?.hasNext || false,
    },
    loading,
    error,
    variables,
    setVariables,
    refetch,
  }
}
