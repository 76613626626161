import { gql, useLazyQuery } from "@apollo/client"

const query = gql`
  query getAccountingExport($ledgerId: String!, $fromDatetime: String!, $toDatetime: String!) {
    getAccountingExportData(ledgerId: $ledgerId, fromDatetime: $fromDatetime, toDatetime: $toDatetime) {
      ... on GetAccountingExportDataOutput {
        accountingData {
          createdAt
          ledgerAccountName
          ledgerAccountNumber
          businessType
          ledgerMovementId
          amount
          apPeriodRevenue
          apFinancedAmount
          apFeeAmount
          apPeriodStartAt
          apPeriodEndAt
          apId
          currency
          apOrders
        }
      }
      ... on SimpleApiError {
        errorCode
        message
      }
    }
  }
`

export interface AccountingData {
  createdAt: string
  ledgerAccountName: string
  ledgerAccountNumber: string
  businessType: string
  ledgerMovementId: string
  amount: string
  apPeriodRevenue: string
  apFinancedAmount: string
  apFeeAmount: string
  apPeriodStartAt: string
  apPeriodEndAt: string
  apId: string
  currency: string
  apOrders: string
}

/**
 * We should create a useHeroLazyQuery
 */
export const useLazyGetAccountingExportData = () => {
  const [getAccountingExportData] = useLazyQuery<{
    getAccountingExportData:
      | {
          accountingData: AccountingData[]
        }
      | {
          errorCode: string
        }
  }>(query, {
    fetchPolicy: "no-cache",
  })

  return async (args: { ledgerId: string; fromDatetime: string; toDatetime: string }): Promise<AccountingData[]> => {
    const query = await getAccountingExportData({
      variables: {
        ledgerId: args.ledgerId,
        fromDatetime: args.fromDatetime,
        toDatetime: args.toDatetime,
      },
    })

    const dataResponse = query.data?.getAccountingExportData

    if (query.error !== undefined) {
      throw new Error(query.error.message)
    }

    if (!dataResponse) {
      return []
    }

    if ("errorCode" in dataResponse) {
      throw new Error(dataResponse.errorCode)
    }

    return dataResponse.accountingData
  }
}
