import {
  ArrowDownRightIcon,
  ArrowUpRightIcon,
  PaperclipFilledIcon,
  Spinner,
  Table,
  TBody,
  THead,
  Typography,
} from "@hero/krypton"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { FlexContainer } from "../../../../../../00_shared/components/Flex"
import { ScrollArea } from "../../../../../../00_shared/components/ScrollArea"
import { useDashboardTranslation } from "../../../../../../01_technical/translations"
import { LedgerMovementFlow } from "../../../../00_shared/business"
import { IconContainer } from "../IconContainer"
import heroLogo from "../../../../../../assets/h-hero.svg"

const StaticTable = styled(Table)`
  width: 100%;

  border: none;

  thead tr th {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.colors.grey.$100};
    border-top: 1px solid ${({ theme }) => theme.colors.grey.$200};
  }
`

const CenteringTD = styled.td`
  text-align: center;
`

const AmountContainer = styled(Typography).attrs({ $variant: "body-4-medium" })<{ $isNegative: boolean }>`
  color: ${({ theme, $isNegative }) => ($isNegative ? theme.colors.grey.$600 : theme.colors.success.$200)};
`

const SubTransactionText = styled(Typography).attrs({ $variant: "caption-2" })`
  color: ${({ theme }) => theme.colors.grey.$500};
`

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

interface Operations {
  id: string
  creditor: string | undefined
  debtor: string | undefined
  label: string | undefined
  isNegative: boolean
  amount: string
  createdAt: string
  operationType: string
  hasJustificative: boolean
  isExternalTransfer: boolean
  flow: LedgerMovementFlow
  ledgerMovementId: string
  isHeroTransfer: boolean
}

interface TransactionTableProps {
  operations: Operations[]
  isLoading: boolean
  isAllAccountView: boolean
}

const TransactionTable = ({ operations, isLoading, isAllAccountView }: TransactionTableProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useDashboardTranslation()

  const handleClickTransaction = (args: {
    transactionId: string
    ledgerMovementId: string
    flow: LedgerMovementFlow
  }) => {
    const searchParams = new URLSearchParams(location.search)
    const { transactionId, ledgerMovementId, flow } = args
    navigate(
      {
        pathname: `./${transactionId}`,
        search: searchParams.toString(),
      },
      {
        state: {
          ledgerMovementId,
          flow,
          transactionId,
        },
      },
    )
  }

  const handleAllAccountOperationView = (operation: Operations) => {
    if (!operation.isExternalTransfer) {
      return (
        <>
          <p>{operation.isNegative ? operation.debtor : operation.creditor}</p>
          <SubTransactionText>{operation.label}</SubTransactionText>
        </>
      )
    }
    return (
      <>
        <p>{operation.label}</p>
        <SubTransactionText>{operation.isNegative ? operation.debtor : operation.creditor}</SubTransactionText>
      </>
    )
  }

  return (
    <ScrollArea>
      <StaticTable>
        <THead>
          <tr>
            <th>{t("accounts.transaction.table.headerColumn.transaction")}</th>
            <th>{t("accounts.transaction.table.headerColumn.operationDate")}</th>
            <th>{t("accounts.transaction.table.headerColumn.method")}</th>
            <th>
              <Centered>{t("accounts.transaction.table.headerColumn.justificative")}</Centered>
            </th>
            <th>{t("accounts.transaction.table.headerColumn.amount")}</th>
          </tr>
        </THead>

        <TBody $clickable={!!operations.length}>
          {isLoading && (
            <tr>
              <CenteringTD colSpan={5}>
                <Spinner />
              </CenteringTD>
            </tr>
          )}
          {!operations.length && !isLoading && (
            <tr>
              <CenteringTD colSpan={5}>{"No transactions found."}</CenteringTD>
            </tr>
          )}
          {operations.map((operation) => (
            <tr
              key={operation.id}
              onClick={handleClickTransaction.bind(null, { ...operation, transactionId: operation.id })}
            >
              <td>
                <FlexContainer $align="center" $gap=".5rem">
                  <IconContainer noPadding={operation.isHeroTransfer}>
                    {operation.isHeroTransfer ? (
                      <HeroIcon src={heroLogo} alt="Hero logo" />
                    ) : operation.isNegative ? (
                      <ArrowDownRightIcon />
                    ) : (
                      <ArrowUpRightIcon />
                    )}
                  </IconContainer>{" "}
                  <FlexContainer $direction="column">
                    {isAllAccountView ? handleAllAccountOperationView(operation) : <p>{operation.label}</p>}
                  </FlexContainer>
                </FlexContainer>
              </td>
              <td>
                <Typography $variant="body-4-regular">{operation.createdAt}</Typography>
              </td>
              <td>{operation.operationType}</td>
              <td>
                <Centered>{operation.hasJustificative ? <PaperclipFilledIcon /> : ""}</Centered>
              </td>
              <td>
                <AmountContainer $isNegative={operation.isNegative}>{operation.amount}</AmountContainer>
              </td>
            </tr>
          ))}
        </TBody>
      </StaticTable>
    </ScrollArea>
  )
}

const HeroIcon = styled.img`
  height: 100%;
`

export default TransactionTable
