import { useEffect, useState } from "react"

export interface MarketplaceTransactionOverviewItem {
  transactionId: string
  date: string
  nbOfReconciledAp: number
  amount: number
  marketplace: {
    id: string
    name: string
    logoUrl: string
  }
}

export const useGetMarketplaceTransactionOverview = (): {
  data: MarketplaceTransactionOverviewItem[] | null
  loading: boolean
  error: { translatedMessage: string } | null
} => {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)

    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [setIsLoading])

  if (false) {
    return {
      data: null,
      error: {
        translatedMessage: "An error occurred",
      },
      loading: false,
    }
  }

  if (isLoading) {
    return {
      data: null,
      error: null,
      loading: true,
    }
  }

  return {
    data: [
      {
        transactionId: "1",
        date: "2024-01-01",
        nbOfReconciledAp: 3,
        amount: 1000,
        marketplace: {
          id: "1",
          name: "Marketplace 1",
          logoUrl: "https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png",
        },
      },
      {
        transactionId: "2",
        date: "2024-01-02",
        nbOfReconciledAp: 0,
        amount: 500,
        marketplace: {
          id: "2",
          name: "Marketplace 2",
          logoUrl: "https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png",
        },
      },
      {
        transactionId: "3",
        date: "2024-01-03",
        nbOfReconciledAp: 1,
        amount: 200,
        marketplace: {
          id: "3",
          name: "Marketplace 3",
          logoUrl: "https://upload.wikimedia.org/wikipedia/commons/d/de/Amazon_icon.png",
        },
      },
    ],
    loading: false,
    error: null,
  }
}
