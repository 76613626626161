import styled from "styled-components"

export const IconContainer = styled.div<{ noPadding?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: ${({ noPadding = false }) => (noPadding ? "unset" : " 0.175rem")};
  background-color: ${({ theme }) => theme.colors.grey.$100};
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};
`
