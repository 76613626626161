import { Typography } from "@hero/krypton"
import styled from "styled-components"
import { useCommonTranslation } from "../../../../../../01_technical/translations"

const Banner = styled.header`
  width: 100%;

  background-color: ${({ theme }) => theme.colors.info.$100};
  border: 2px solid ${({ theme }) => theme.colors.info.$200};
  top: 0;
  padding: 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 8px;
  border-radius: 8px;
`

export const AccountClosedBanner = ({ closingDate }: { closingDate: string }) => {
  const { t } = useCommonTranslation()

  return (
    <Banner>
      <Typography $color="info.$200" $variant="body-4-regular">
        {`${t("account.closed.banner.label")} ${closingDate}`}
      </Typography>
    </Banner>
  )
}
