import { Typography, ValidIcon } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../../01_technical/translations"
import { ApRegulatedStatusEnum } from "../hooks/getAcceleratedPayoutList"

export const StatusBadge = ({ status }: { status: ApRegulatedStatusEnum }) => {
  const { t } = useDashboardTranslation()

  if ([ApRegulatedStatusEnum.OPENED, ApRegulatedStatusEnum.PARTIALLY_REPAID].includes(status)) {
    return (
      <StatusBadgeWrapper>
        <StatusOpen>
          <Typography $variant="caption-1">{t("apRefunded.dashboard.apListSection.status.opened")}</Typography>
        </StatusOpen>
      </StatusBadgeWrapper>
    )
  }

  if ([ApRegulatedStatusEnum.OPENING_CANCELLED].includes(status)) {
    return (
      <StatusBadgeWrapper>
        <Cancelled>
          <Typography $variant="caption-1">{t("apRefunded.dashboard.apListSection.status.cancelled")}</Typography>
        </Cancelled>
      </StatusBadgeWrapper>
    )
  }

  if (status === ApRegulatedStatusEnum.TOTALLY_REPAID) {
    return (
      <StatusBadgeWrapper>
        <StatusTotallyRepaid>
          <Typography $variant="caption-1">{t("apRefunded.dashboard.apListSection.status.totallyRepaid")}</Typography>
          <CheckIcon />
        </StatusTotallyRepaid>
      </StatusBadgeWrapper>
    )
  }
}

const CheckIcon = styled(ValidIcon)`
  color: ${({ theme }) => theme.colors.primary.$300};
  height: 1rem;
  width: 1rem;
`

const StatusBadgeWrapper = styled.div`
  display: flex;
`

const StatusBadgeBase = styled.div`
  display: flex;
  align-self: self-start;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
`

const StatusOpen = styled(StatusBadgeBase)`
  background-color: ${({ theme }) => theme.colors.info.$100};
  & > p {
    color: ${({ theme }) => theme.colors.info.$200};
  }
`

const Cancelled = styled(StatusBadgeBase)`
  background-color: ${({ theme }) => theme.colors.danger.$100};
  & > p {
    color: ${({ theme }) => theme.colors.danger.$300};
  }
`

const StatusTotallyRepaid = styled(StatusBadgeBase)`
  background-color: ${({ theme }) => theme.colors.primary.$100};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  & > p {
    color: ${({ theme }) => theme.colors.primary.$300};
  }
`
