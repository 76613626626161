import { Button, CloseStrokeIcon, theme, Typography } from "@hero/krypton"
import * as SheetPrimitive from "@radix-ui/react-dialog"
import * as React from "react"
import styled, { css, keyframes } from "styled-components"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOutToRight = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`

const slideOutToLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const slideInFromTop = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

const slideOutToTop = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`

const slideInFromBottom = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const slideOutToBottom = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
`

const CloseButtonWrapper = styled.div`
  position: fixed;
  right: 2rem;
  top: 2rem;
`

// Styled Components
const Sheet = SheetPrimitive.Root
const SheetTrigger = SheetPrimitive.Trigger
const SheetClose = SheetPrimitive.Close
const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = styled(SheetPrimitive.Overlay)`
  position: fixed;
  inset: 0;
  z-index: 50;
  background: rgba(0, 0, 0, 0.15);
  &[data-state="open"] {
    animation: ${fadeIn} 0.3s;
  }
  &[data-state="closed"] {
    animation: ${fadeOut} 0.3s;
  }
`

const sheetVariants = {
  top: css`
    left: 0;
    right: 0;
    top: 0;
    height: 30%;
    border-bottom: 1px solid ${theme.colors.grey.$300};
    &[data-state="closed"] {
      animation: ${slideOutToTop} 0.3s;
    }
    &[data-state="open"] {
      animation: ${slideInFromTop} 0.5s;
    }
  `,
  bottom: css`
    left: 0;
    right: 0;
    bottom: 0;
    height: 30%;
    border-top: 1px solid ${theme.colors.grey.$300};
    &[data-state="closed"] {
      animation: ${slideOutToBottom} 0.3s;
    }
    &[data-state="open"] {
      animation: ${slideInFromBottom} 0.5s;
    }
  `,
  left: css<SheetContentProps>`
    top: 0;
    bottom: 0;
    left: 0;
    width: 75%;
    height: 100%;
    border-right: 1px solid ${theme.colors.grey.$300};
    max-width: ${({ maxWidth }) => maxWidth || "500px"}; // Allow dynamic maxWidth
    @media (max-width: 768px) {
      width: 100vw;
    }
    &[data-state="closed"] {
      animation: ${slideOutToLeft} 0.3s;
    }
    &[data-state="open"] {
      animation: ${slideInFromLeft} 0.5s;
    }
  `,
  right: css<SheetContentProps>`
    top: 0;
    bottom: 0;
    right: 0;
    width: 75%;
    height: 100%;
    border-left: 1px solid ${theme.colors.grey.$300};
    max-width: ${({ maxWidth }) => maxWidth || "500px"}; // Allow dynamic maxWidth
    @media (max-width: 768px) {
      width: 100vw;
      border-left: none;
    }
    &[data-state="closed"] {
      animation: ${slideOutToRight} 0.3s;
    }
    &[data-state="open"] {
      animation: ${slideInFromRight} 0.5s;
    }
  `,
}

type SheetVariants = keyof typeof sheetVariants

const getSideVariant = (side: SheetVariants) => sheetVariants[side]

interface SheetContentProps extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content> {
  side?: SheetVariants
  maxWidth?: string // Add a new prop for dynamic max-width
}

const SheetContentWrapper = styled(SheetPrimitive.Content)<SheetContentProps>`
  display: flex;
  flex-direction: column;

  position: fixed;
  z-index: 50;
  background: ${theme.colors.white};
  padding: 2rem;
  box-shadow: ${theme.shadows.light};
  transition: all 0.3s ease-in-out;

  overflow-y: auto;
  overflow-x: hidden;

  ${({ side = "right" }) => getSideVariant(side)}

  @media (max-width: 768px) {
    padding-top: 1rem;
  }

  outline: none; // Remove the default outline
  box-shadow: none; // Remove any box shadow applied by focus-visible
  border: none; // Remove border if any applied by focus-visible
`

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = "right", maxWidth = "500px", children, ...props }, ref) => (
    <SheetPortal>
      <SheetOverlay />
      <SheetContentWrapper
        ref={ref}
        side={side}
        maxWidth={maxWidth} // Apply the dynamic maxWidth
        onOpenAutoFocus={(event) => {
          event.preventDefault()
        }}
        {...props}
      >
        {children}
        <SheetClose asChild>
          <CloseButtonWrapper>
            <Button $variant="round" leftIcon={CloseStrokeIcon} isLoading={false} />
          </CloseButtonWrapper>
        </SheetClose>
      </SheetContentWrapper>
    </SheetPortal>
  ),
)
SheetContent.displayName = SheetPrimitive.Content.displayName

const SheetHeaderWrapper = styled.div`
  position: relative;
  padding: 1rem 0rem 2rem 0rem;
  margin-bottom: 1.5rem;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${theme.colors.grey.$300};
  }
`

const SheetFooterWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 640px) {
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5rem;
  }
`

const SheetTitleWrapper = styled(Typography).attrs(() => ({
  $variant: "body-3-medium",
}))`
  color: ${theme.colors.black.$900};
  @media (max-width: 768px) {
    // Apply title-3-semibold for screens below 768px
    ${({ theme }) => theme.typography["title-3-semibold"]}
  }
`

const SheetDescriptionWrapper = styled(SheetPrimitive.Description)`
  font-size: 0.875rem;
  color: ${theme.colors.grey.$600};
`

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return <SheetHeaderWrapper className={className} {...props} />
}
SheetHeader.displayName = "SheetHeader"

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <SheetFooterWrapper className={className} {...props} />
)
SheetFooter.displayName = "SheetFooter"

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ ...props }, ref) => <SheetTitleWrapper ref={ref} {...props} />)
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ ...props }, ref) => <SheetDescriptionWrapper ref={ref} {...props} />)
SheetDescription.displayName = SheetPrimitive.Description.displayName

export {
  Sheet,
  SheetTrigger,
  // SheetClose,
  SheetContent,
  // SheetDescription,
  // SheetFooter,
  SheetHeader,
  SheetTitle,
}
