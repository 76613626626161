import { useMutation, useQuery } from "@apollo/client"
import {
  Badge,
  Button,
  Field,
  Header,
  Separator,
  Spinner,
  Tab,
  Table,
  Tabs,
  toaster,
  Toggle,
  Typography,
} from "@hero/krypton"
import { zodResolver } from "@hookform/resolvers/zod"
import { isValidPhoneNumber } from "libphonenumber-js"
import { ChangeEvent, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link, Navigate, Outlet, useLocation } from "react-router-dom"
import styled from "styled-components"
import { z } from "zod"
import { Dialog, DialogContent } from "../../00_shared/components/Dialog"
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "../../00_shared/components/Sheet"
import { Spacer } from "../../00_shared/components/Spacer"
import { PhoneSchema } from "../../00_shared/utils/commonZodSchemas.utils"
import { DateStyle, toInternationalDate } from "../../00_shared/utils/date.converter"
import { forEnumOrElse } from "../../00_shared/utils/enum"
import { CommonTFunction, useCommonTranslation, useDashboardTranslation } from "../../01_technical/translations"
import { BOV3RouteProtector } from "../../App.router"
import { useAuthContext } from "../../Auth/auth.context"
import { TableBody, TableCell, TableHead, TableRow } from "../../00_shared/components/krypton2/Table"
import { INVITE_USER_MERCHANT_MUTATION } from "../Tools/Settings/MerchantUser/inviteMerchantUser/inviteMerchantUser.requests"
import {
  CHANGE_MERCHANT_USER_ROLE,
  CHANGE_MERCHANT_USER_STATUS,
  LIST_MERCHANT_USERS,
  RESEND_MERCHANT_USER_INVITATION,
} from "../Tools/Settings/MerchantUser/listMerchantUser.requests"

const fullname = ({ firstname, lastname }: Pick<User, "firstname" | "lastname">) => {
  const fullnameArray = [firstname, lastname].filter(Boolean)
  if (fullnameArray.length === 0) {
    return null
  }
  return fullnameArray.join(" ")
}

const TabsContainer = styled(Tabs)`
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-around;

    & button,
    a {
      flex: 1;
      text-align: center;
      justify-content: center;
      margin-right: 0;
    }
  }
`

const membersPath = "/user-management/members"

const UserManagementHeaderTabs = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()

  const isMembersSelected = location.pathname.startsWith(membersPath)

  return (
    <TabsContainer>
      <Tab as={Link} $isSelected={isMembersSelected} to={membersPath}>
        {t("userManagement.list.tabs.members")}
      </Tab>
    </TabsContainer>
  )
}

const HeaderContainer = styled(Header)`
  position: relative;
  display: flex;
  justify-content: space-after;
  align-items: start;
  overflow-x: auto;
  flex: 1;

  @media (max-width: 768px) {
    padding: 1rem 1rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    min-height: 0;

    &::before {
      background: none;
      width: unset;
      content: none;
    }
  }

  &::before {
    background: none;
    width: unset;
  }
`

type User = {
  id: string
  firstname: string
  lastname: string
  email: string
  status: "ACTIVE" | "INACTIVE" | "INVITED" | "SUSPENDED" | "DELETED"
  role: "OWNER" | "MEMBER"
  isLegalRepresentative: boolean
  createdAt: Date
  phone: string
}

const UserStatusBadge = ({ status }: Pick<User, "status">) => {
  const { t } = useDashboardTranslation()

  return forEnumOrElse(
    status,
    {
      ACTIVE: () => <Badge $variant="primary">{t("userManagement.userStatus.active")}</Badge>,
      DELETED: () => <Badge $variant="disabled">{t("userManagement.userStatus.deleted")}</Badge>,
      INACTIVE: () => <Badge $variant="disabled">{t("userManagement.userStatus.inactive")}</Badge>,
      INVITED: () => <Badge $variant="secondary">{t("userManagement.userStatus.invited")}</Badge>,
      SUSPENDED: () => <Badge $variant="danger">{t("userManagement.userStatus.suspended")}</Badge>,
    },
    // eslint-disable-next-line i18next/no-literal-string
    () => <div>unknown status</div>,
  )
}

const UserRole = ({ role, isLegalRepresentative }: Pick<User, "role" | "isLegalRepresentative">) => {
  const { t } = useDashboardTranslation()

  return forEnumOrElse(
    role,
    {
      OWNER: () => {
        if (isLegalRepresentative) {
          return <Typography $variant="caption-2">{t("userManagement.userRole.owner_and_legalrep")}</Typography>
        }
        return <Typography $variant="caption-2">{t("userManagement.userRole.owner")}</Typography>
      },
      MEMBER: () => <Typography $variant="caption-2">{t("userManagement.userRole.member")}</Typography>,
    },
    // eslint-disable-next-line i18next/no-literal-string
    () => <div>unknown role</div>,
  )
}

const UserInfoWrapper = styled.div`
  padding: 16px 0;
`

const UserInfo = ({ user }: { user: Pick<User, "firstname" | "lastname" | "email" | "status"> }) => {
  return (
    <UserInfoWrapper>
      {["SUSPENDED", "INACTIVE"].includes(user.status) ? (
        <Typography $variant="body-4-medium" $color="grey.$400">
          <span style={{ textDecoration: "line-through" }}>{fullname(user)}</span>
        </Typography>
      ) : (
        <Typography $variant="body-4-medium">{fullname(user)}</Typography>
      )}

      {["SUSPENDED", "INACTIVE"].includes(user.status) ? (
        <Typography $variant="caption-2" $color="grey.$400">
          <span style={{ textDecoration: "line-through" }}>{user.email}</span>
        </Typography>
      ) : (
        <Typography $variant="caption-2">
          <span>{user.email}</span>
        </Typography>
      )}
    </UserInfoWrapper>
  )
}

const MembersWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const MembersListWrapper = styled.div`
  flex: 0 0 66.66%;
`

const ContextPanelWrapper = styled.div`
  flex: 0 0 33.33%;
  padding: 0 24px;
`

const ContextPanel = () => {
  const { t } = useDashboardTranslation()

  return (
    <ContextPanelWrapper>
      <Typography $variant="body-1-medium">{t("userManagement.contextPanel.accessControl.header")}</Typography>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.accessControl.description")}</Typography>

      <Spacer />

      <Typography $variant="body-1-medium">{t("userManagement.contextPanel.collaborator.header")}</Typography>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.collaborator.description")}</Typography>

      <Spacer />

      <Typography $variant="body-1-medium">{t("userManagement.contextPanel.adminRole.header")}</Typography>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.adminRole.description")}</Typography>

      <Spacer />

      <Typography $variant="body-1-medium">{t("userManagement.contextPanel.ownerRole.header")}</Typography>
      <Typography $variant="caption-2">{t("userManagement.contextPanel.ownerRole.description")}</Typography>

      <Spacer />
      <Separator />
      <Spacer />

      <Typography $variant="caption-2">{t("userManagement.contextPanel.moreInfo")}</Typography>
    </ContextPanelWrapper>
  )
}

const PlainButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`

const bySearchStringFilter =
  (searchString: string) =>
  ({ firstname, lastname, email }: Pick<User, "email" | "firstname" | "lastname">) => {
    const lowerCaseSearchString = (searchString?.toLowerCase() ?? "").trim()
    return (
      firstname.toLowerCase().includes(lowerCaseSearchString) ||
      lastname.toLowerCase().includes(lowerCaseSearchString) ||
      email.toLowerCase().includes(lowerCaseSearchString)
    )
  }

const byNameSorter = (a: Pick<User, "firstname" | "lastname">, b: Pick<User, "firstname" | "lastname">) => {
  const nameA = fullname(a) ?? ""
  const nameB = fullname(b) ?? ""
  return nameA.localeCompare(nameB)
}

const MembersList = ({
  users,
  searchString,
  seeMore,
  editUser,
}: {
  users: User[]
  searchString: string
  seeMore: (user: User) => void
  editUser: (user: User) => void
}) => {
  const someInvitationsAreStillPending = users.some((user) => user.status === "INVITED")

  const { t } = useDashboardTranslation()
  const { currentUser } = useAuthContext()

  return (
    <MembersListWrapper>
      <Table type="card">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography $variant="caption-2">{t("userManagement.list.header.name")}</Typography>
            </TableCell>
            <TableCell>
              <Typography $variant="caption-2">{t("userManagement.list.header.status")}</Typography>
            </TableCell>
            <TableCell>
              <Typography $variant="caption-2">{t("userManagement.list.header.role")}</Typography>
            </TableCell>
            <TableCell></TableCell>
            {currentUser.role === "owner" && someInvitationsAreStillPending && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {users
            .filter(bySearchStringFilter(searchString))
            .toSorted(byNameSorter)
            .map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <UserInfo user={user} />
                </TableCell>

                <TableCell>
                  <UserStatusBadge status={user.status} />
                </TableCell>

                <TableCell>
                  <UserRole role={user.role} isLegalRepresentative={user.isLegalRepresentative}></UserRole>
                </TableCell>

                {currentUser.role === "owner" &&
                  (user.status === "INVITED" ? (
                    <TableCell>
                      <InvitationButton user={user} />
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  ))}

                <TableCell>
                  {currentUser.role === "owner" && !user.isLegalRepresentative ? (
                    <Typography
                      onClick={() => editUser(user)}
                      style={{ textAlign: "right", cursor: "pointer" }}
                      $variant="body-4-medium"
                    >
                      {t("userManagement.row.edit")}
                    </Typography>
                  ) : (
                    <Typography
                      onClick={() => seeMore(user)}
                      style={{ textAlign: "right", cursor: "pointer" }}
                      $variant="body-4-medium"
                    >
                      {t("userManagement.row.seeMore")}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </MembersListWrapper>
  )
}

const SRPBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CheckboxGroup = styled.label<{ $isChecked?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  border: 1px solid ${({ theme, $isChecked }) => ($isChecked ? theme.colors.grey.$600 : theme.colors.grey.$200)};
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  gap: 0.5rem;

  transition: all 0.2s;

  & * {
    transition: all 0.2s;
  }
`

const Checkbox = styled.input`
  margin-top: 0.3125rem;
  height: 0.875rem;
  width: 0.875rem;
  border: 1px solid ${({ theme }) => theme.colors.grey.$200};

  accent-color: ${({ theme }) => theme.colors.grey.$600};
`

const InvitationButton = ({ user }: { user: User }) => {
  const { t } = useDashboardTranslation()
  const [resendInvitation] = useMutation(RESEND_MERCHANT_USER_INVITATION)
  const resendInviteToUser = ({ id }: Pick<User, "id">) => {
    toaster.promise(
      resendInvitation({ variables: { userId: id } }),
      {
        pending: t("merchant.tools.setting.merchantUser.invited.pending"),
        success: t("merchant.tools.setting.merchantUser.invited.success"),
        error: t("merchant.tools.setting.merchantUser.invited.error"),
      },
      { position: "top-center" },
    )
  }

  return (
    <PlainButton
      style={{ padding: "8px", backgroundColor: "#F7F7FF" }}
      onClick={() => {
        resendInviteToUser(user)
      }}
    >
      <Typography $variant="body-3-medium">{t("userManagement.row.resendInvitation")}</Typography>
    </PlainButton>
  )
}

const SlidingRighPanel = ({
  user,
  onClose,
  refetchUserList,
}: {
  refetchUserList: () => void
  user: User
  onClose: () => void
}) => {
  const { currentUser } = useAuthContext()
  const { t, lang } = useDashboardTranslation()

  const userIsOwnerWithLegalRepresentativeRole = user.role === "OWNER" && user.isLegalRepresentative
  const [changeMerchantUserStatus] = useMutation(CHANGE_MERCHANT_USER_STATUS)
  const [changeMerchantUserRole] = useMutation(CHANGE_MERCHANT_USER_ROLE)

  const activateUser = (user: User) => {
    toaster.promise(
      changeMerchantUserStatus({ variables: { userId: user.id, status: "ACTIVE" } }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.activateUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.activateUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.activateUserStatus.error"),
      },
      { position: "top-center" },
    )
  }
  const deactivateUser = (user: User) => {
    toaster.promise(
      changeMerchantUserStatus({ variables: { userId: user.id, status: "INACTIVE" } }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.disableUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.disableUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.disableUserStatus.error"),
      },
      { position: "top-center" },
    )
  }

  const changeUserOwner = (user: User) => {
    toaster.promise(
      changeMerchantUserRole({ variables: { userId: user.id, role: "OWNER" } }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserStatus.error"),
      },
      { position: "top-center" },
    )
  }
  const changeUserMember = (user: User) => {
    toaster.promise(
      changeMerchantUserRole({ variables: { userId: user.id, role: "MEMBER" } }).then(() => refetchUserList()),
      {
        pending: t("merchant.tools.setting.merchantUser.changeUserStatus.pending"),
        success: t("merchant.tools.setting.merchantUser.changeUserStatus.success"),
        error: t("merchant.tools.setting.merchantUser.changeUserStatus.error"),
      },
      { position: "top-center" },
    )
  }

  return (
    <Sheet
      open={!!user}
      onOpenChange={(open) => {
        if (open) {
          return
        }
        onClose()
      }}
    >
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle>{t("userManagement.sidePanel.header.title")}</SheetTitle>
        </SheetHeader>

        <SRPBlock>
          <div>
            <Typography $variant="title-2-bold">{fullname(user)}</Typography>
            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("userManagement.sidePanel.nameBlock.createdOn", {
                createdAt: toInternationalDate({ date: user.createdAt, language: lang, style: DateStyle.TEXTUAL }),
              })}
            </Typography>
          </div>
          <div>
            <UserStatusBadge status={user.status} />
          </div>
        </SRPBlock>

        {currentUser.role === "owner" && user.status === "INVITED" && <InvitationButton user={user} />}

        <Spacer />
        <Separator />
        <Spacer />

        {userIsOwnerWithLegalRepresentativeRole ? (
          <CheckboxGroup disabled={true} $isChecked={user.role === "OWNER"}>
            <div>
              <Checkbox disabled={true} type="radio" checked={user.role === "OWNER"} name="selected-role" />
            </div>
            <div>
              <Typography $variant="body-4-medium">
                {t("userManagement.sidePanel.roleToggle.ownerPlusLegalRep.title")}
              </Typography>
              <Typography $variant="caption-2">
                {t("userManagement.sidePanel.roleToggle.ownerPlusLegalRep.description")}
              </Typography>
            </div>
          </CheckboxGroup>
        ) : (
          <>
            <CheckboxGroup $isChecked={user.role === "MEMBER"}>
              <div>
                <Checkbox
                  type="radio"
                  checked={user.role === "MEMBER"}
                  name="selected-role"
                  onChange={(e) => {
                    changeUserMember(user)
                  }}
                />
              </div>
              <div>
                <Typography $variant="body-4-medium">
                  {t("userManagement.sidePanel.roleToggle.collaborator.title")}
                </Typography>
                <Typography $variant="caption-2">
                  {t("userManagement.sidePanel.roleToggle.collaborator.description")}
                </Typography>
              </div>
            </CheckboxGroup>

            <Spacer />

            <CheckboxGroup $isChecked={user.role === "OWNER"}>
              <div>
                <Checkbox
                  type="radio"
                  checked={user.role === "OWNER"}
                  name="selected-role"
                  onChange={(e) => {
                    changeUserOwner(user)
                  }}
                />
              </div>
              <div>
                <Typography $variant="body-4-medium">{t("userManagement.sidePanel.roleToggle.admin.title")}</Typography>
                <Typography $variant="caption-2">
                  {t("userManagement.sidePanel.roleToggle.admin.description")}
                </Typography>
              </div>
            </CheckboxGroup>
          </>
        )}

        <Spacer />
        <Separator />
        <Spacer />

        {!userIsOwnerWithLegalRepresentativeRole && (
          <>
            <Typography $variant="body-3-semibold">
              {t("userManagement.sidePanel.status.title")}
              <span style={{ padding: "1em" }}>
                {user.status === "SUSPENDED" && <UserStatusBadge status={user.status} />}
              </span>
            </Typography>
            <SRPBlock>
              <Typography $variant="body-4-regular" $color="grey.$500" aria-label="status-description">
                {t("userManagement.sidePanel.status.description.inactive")}
              </Typography>
              {user.status !== "SUSPENDED" && (
                <div>
                  <Toggle
                    checked={user.status === "ACTIVE"}
                    aria-labelledby="status-description"
                    onClick={() => {
                      if (user.status === "ACTIVE") {
                        deactivateUser(user)
                      }
                      if (user.status === "INACTIVE") {
                        activateUser(user)
                      }
                    }}
                  />
                </div>
              )}
            </SRPBlock>

            <Spacer />
            <Separator />
            <Spacer />
          </>
        )}

        <Typography $variant="body-3-semibold">{t("userManagement.sidePanel.memberInformation.title")}</Typography>
        <SRPBlock>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {t("userManagement.sidePanel.memberInformation.email")}
          </Typography>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {user.email}
          </Typography>
        </SRPBlock>

        <SRPBlock>
          <Typography $variant="body-4-regular" $color="grey.$500">
            {t("userManagement.sidePanel.memberInformation.phoneNumber")}
          </Typography>
          <Typography $variant="body-4-regular" $color="grey.$600">
            {user.phone}
          </Typography>
        </SRPBlock>

        {userIsOwnerWithLegalRepresentativeRole && (
          <>
            <Spacer />
            <Separator />
            <Spacer />

            <Typography $variant="body-4-regular" $color="grey.$500">
              {t("userManagement.sidePanel.disclaimerForLegalRep")}
            </Typography>
          </>
        )}
      </SheetContent>
    </Sheet>
  )
}

const Members = () => {
  const [searchString, setSearchString] = useState("")
  const { t } = useDashboardTranslation()
  const [sidePanelOpenedForUserId, setSidePanelOpenedForUserId] = useState<string | null>(null)
  const { impersonateBannerHeight } = useAuthContext()
  const { data, loading, refetch: refetchUserList } = useQuery<{ listMerchantUsers: User[] }>(LIST_MERCHANT_USERS)
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false)

  if (loading) {
    return <Spinner />
  }

  const users = data?.listMerchantUsers ?? []
  const sidePanelUser = users.find((user) => user.id === sidePanelOpenedForUserId)

  return (
    <>
      <Dialog open={isInviteModalOpen} onOpenChange={setIsInviteModalOpen}>
        <DialogContent
          disableCloseButton={false}
          $disableAnimations={true}
          width="100%"
          height="100%"
          topOffset={impersonateBannerHeight}
        >
          <InviteUser
            onInviteSuccess={() => {
              setIsInviteModalOpen(false)
              refetchUserList()
            }}
          />
        </DialogContent>
      </Dialog>

      {sidePanelUser && (
        <SlidingRighPanel
          refetchUserList={refetchUserList}
          user={sidePanelUser}
          onClose={() => setSidePanelOpenedForUserId(null)}
        />
      )}
      <Field
        type="search"
        placeholder={t("userManagement.list.searchPlaceholder")}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchString(event.target.value)}
      />
      <Button onClick={() => setIsInviteModalOpen(true)} $variant="primary">
        {t("userManagement.list.addMember")}
      </Button>
      <MembersWrapper>
        <MembersList
          users={users}
          searchString={searchString}
          seeMore={(user) => setSidePanelOpenedForUserId(user.id)}
          editUser={(user) => setSidePanelOpenedForUserId(user.id)}
        />
        <ContextPanel />
      </MembersWrapper>
    </>
  )
}

const Container = styled.div`
  height: 100%;
  width: 40rem;
  margin: auto;
  padding: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`

const getInviteFormSchema = (t: CommonTFunction) => {
  return z.object({
    email: z.string().email(),
    firstname: z.string().trim().min(1),
    lastname: z.string().trim().min(1),
    role: z.enum(["OWNER", "MEMBER"]),
    phone: PhoneSchema(t),
  })
}

const useInviteForm = () => {
  const { t } = useCommonTranslation()
  const exportInviteSchema = getInviteFormSchema(t)
  type SchemaType = z.infer<typeof exportInviteSchema>

  return useForm<SchemaType>({
    resolver: zodResolver(exportInviteSchema),
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      role: "MEMBER",
      phone: "",
    },
  })
}

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const InviteUser = ({ onInviteSuccess }: { onInviteSuccess: () => void }) => {
  const [inviteUserMerchantMutation] = useMutation(INVITE_USER_MERCHANT_MUTATION)

  const [formStep, setFormStep] = useState<"EMAIL_AND_NAMES_SELECTION" | "ROLE_SELECTION">("EMAIL_AND_NAMES_SELECTION")
  const { t } = useDashboardTranslation()

  const form = useInviteForm()
  const onSubmit = form.handleSubmit((formData) => {
    toaster.promise(
      inviteUserMerchantMutation({
        variables: {
          email: formData.email,
          firstname: formData.firstname,
          lastname: formData.lastname,
          phone: formData.phone,
          role: formData.role,
        },
      }).then(() => {
        onInviteSuccess()
      }),
      {
        pending: t("merchant.tools.setting.merchantUser.invited.pending"),
        success: t("merchant.tools.setting.merchantUser.invited.success"),
        error: t("merchant.tools.setting.merchantUser.invited.error"),
      },
      { position: "top-center" },
    )
  })

  return (
    <Container>
      <StyledForm onSubmit={onSubmit}>
        {formStep === "EMAIL_AND_NAMES_SELECTION" && (
          <>
            <Typography $variant="title-3-semibold">{t("userManagement.invite.addMemberToYourTeam")}</Typography>

            <Spacer />

            <Controller
              control={form.control}
              name="email"
              render={({ field, fieldState }) => (
                <Field
                  fieldLabel={t("userManagement.invite.form.email")}
                  type="email"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Spacer />

            <Controller
              control={form.control}
              name="firstname"
              render={({ field, fieldState }) => (
                <Field
                  fieldLabel={t("userManagement.invite.form.firstname")}
                  type="text"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Spacer />

            <Controller
              control={form.control}
              name="lastname"
              render={({ field, fieldState }) => (
                <Field
                  fieldLabel={t("userManagement.invite.form.lastname")}
                  type="text"
                  $fullWidth
                  errorMessage={fieldState.error?.message}
                  onBlur={field.onBlur}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => field.onChange(e.currentTarget.value)}
                  value={field.value}
                />
              )}
            />

            <Spacer />

            <Controller
              name="phone"
              control={form.control}
              rules={{
                validate: (value = "") => isValidPhoneNumber(value.toString()) || t("formValidation.common.invalid"),
              }}
              render={({ field, fieldState }) => (
                <Field
                  {...field}
                  fieldLabel={t("bnpl.link.wizard.steps.client.phoneNumber")}
                  type="tel"
                  $fullWidth
                  defaultCountry="FR"
                  errorMessage={fieldState.error?.message}
                />
              )}
            />

            <Spacer />

            <Button
              onClick={() => setFormStep("ROLE_SELECTION")}
              disabled={!form.formState.isValid}
              type="button"
              $variant="primary"
              style={{
                minWidth: "auto",
              }}
            >
              {t("userManagement.invite.continue")}
            </Button>
          </>
        )}

        {formStep === "ROLE_SELECTION" && (
          <>
            <Typography $variant="title-3-semibold">{t("userManagement.invite.selectRole")}</Typography>

            <Spacer />

            <Controller
              control={form.control}
              name="role"
              render={({ field }) => (
                <>
                  <CheckboxGroup $isChecked={field.value === "MEMBER"}>
                    <div>
                      <Checkbox
                        type="radio"
                        checked={field.value === "MEMBER"}
                        name="selected-role"
                        onChange={(e) => {
                          field.onChange("MEMBER")
                        }}
                      />
                    </div>
                    <div>
                      <Typography $variant="body-4-medium">
                        {t("userManagement.sidePanel.roleToggle.collaborator.title")}
                      </Typography>
                      <Typography $variant="caption-2">
                        {t("userManagement.sidePanel.roleToggle.collaborator.description")}
                      </Typography>
                    </div>
                  </CheckboxGroup>

                  <Spacer />

                  <CheckboxGroup $isChecked={field.value === "OWNER"}>
                    <div>
                      <Checkbox
                        type="radio"
                        checked={field.value === "OWNER"}
                        name="selected-role"
                        onChange={(e) => {
                          field.onChange("OWNER")
                        }}
                      />
                    </div>
                    <div>
                      <Typography $variant="body-4-medium">
                        {t("userManagement.sidePanel.roleToggle.admin.title")}
                      </Typography>
                      <Typography $variant="caption-2">
                        {t("userManagement.sidePanel.roleToggle.admin.description")}
                      </Typography>
                    </div>
                  </CheckboxGroup>

                  <Spacer />

                  <Button type="submit" $fullWidth size="medium" isLoading={false}>
                    {t("userManagement.invite.submit")}
                  </Button>
                </>
              )}
            />
          </>
        )}
      </StyledForm>
    </Container>
  )
}

const UserManagementScreen = () => {
  const { t } = useDashboardTranslation()
  const location = useLocation()

  const membersPath = "/user-management/members"
  const isMembersSelected = location.pathname.startsWith(membersPath)

  return (
    <HeaderContainer>
      <Typography $variant="title-3-semibold">{t("userManagement.header.title")}</Typography>
      <Typography $variant="body-4-regular">{t("userManagement.header.subtitle")}</Typography>
      <UserManagementHeaderTabs />
      <Separator />
      <Spacer />
      {isMembersSelected && <Members />}
    </HeaderContainer>
  )
}

export const userManagementRoute = {
  path: "user-management",
  element: (
    <BOV3RouteProtector>
      <Outlet />
    </BOV3RouteProtector>
  ),
  children: [
    {
      index: true,
      element: <Navigate to="members" />,
    },
    {
      path: "members",
      children: [
        {
          path: "",
          element: <UserManagementScreen />,
        },
      ],
    },
  ],
}
