import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { CommonTFunction, useCommonTranslation } from "../../../01_technical/translations"
import { DateSchema } from "../../../00_shared/utils/commonZodSchemas.utils"
import { addMonths } from "../utils"

const getExportFormSchema = (t: CommonTFunction) => {
  return z
    .object({
      fromDatetime: DateSchema(t),
      toDatetime: DateSchema(t),
      format: z.enum(["xlsx", "ods"]),
      includeAccounting: z.literal(true),
    })
    .refine((data) => data.fromDatetime <= data.toDatetime, {
      message: t("formValidation.date.invalidRange"),
      path: ["toDatetime"],
    })
}

export const useExportForm = () => {
  const { t } = useCommonTranslation()
  const exportFormSchema = getExportFormSchema(t)
  type SchemaType = z.infer<typeof exportFormSchema>

  return useForm<SchemaType>({
    resolver: zodResolver(exportFormSchema),
    defaultValues: {
      fromDatetime: addMonths(new Date(), -1),
      toDatetime: new Date(),
      includeAccounting: true,
      format: "xlsx",
    },
  })
}
