import { RouteObject } from "react-router-dom"
import All2faProviders from "../00_shared/providers/Providers2fa"
import { Connect } from "../01_technical/client"
import { OnboardMerchantUserScreen } from "./CreatePassword/onboardMerchantUser.screen"
import { ForgotPassword } from "./ForgotPassword/ForgotPassword"
import { Login } from "./Login/Login.screen"
import { Autologin } from "./LoginAs/Autologin"
import { LoginAs } from "./LoginAs/LoginAs"
import { Logout } from "./Logout/Logout"
import { ResetPassword } from "./ResetPassword/ResetPassword"
import { SignupScreen } from "./Signup/Signup.screen"
import React, { ReactNode } from "react"
import { AuthLayout } from "./auth.layout"

const AuthWith2FA: React.FC<{ children: ReactNode }> = ({ children }) => (
  <All2faProviders>
    <AuthLayout>{children}</AuthLayout>
  </All2faProviders>
)

export const authRoutes: RouteObject[] = [
  {
    path: "login_as",
    element: (
      <AuthWith2FA>
        <LoginAs />
      </AuthWith2FA>
    ),
  },
  {
    path: "autologin",
    element: (
      <AuthWith2FA>
        <Autologin />
      </AuthWith2FA>
    ),
  },
  {
    path: "signup",
    element: (
      <AuthWith2FA>
        <SignupScreen />
      </AuthWith2FA>
    ),
  },
  {
    path: "login",
    element: (
      <AuthWith2FA>
        <Login />
      </AuthWith2FA>
    ),
  },
  {
    path: "forgot_password",
    element: (
      <AuthWith2FA>
        <ForgotPassword />
      </AuthWith2FA>
    ),
  },
  {
    path: "reset_password",
    element: (
      <AuthWith2FA>
        <ResetPassword />
      </AuthWith2FA>
    ),
  },
  {
    path: "create_password",
    element: (
      <AuthWith2FA>
        <OnboardMerchantUserScreen />
      </AuthWith2FA>
    ),
  },
  {
    path: "logout",
    element: (
      <Connect>
        <Logout />
      </Connect>
    ),
  },
]
