import { Header, Typography } from "@hero/krypton"
import styled from "styled-components"
import { useDashboardTranslation } from "../../01_technical/translations"
import { HEADER_COLORS } from "../../env_variables"
import { apRegulatedBreakpoints as breakpoints } from "./utils"
import { AcceleratedPayoutsSection } from "./components/AcceleratedPayoutsSection"
import { MarketplaceTransfersOverview } from "./components/MarketplaceTransfersOverview"
import { AcceleratedPayoutDetails } from "./components/AcceleratedPayoutDetails"
import { useState } from "react"

export const HomeScreen = () => {
  const { t } = useDashboardTranslation()
  const [displayedDetailsId, setDisplayedDetailsId] = useState("")

  return (
    <PageWrapper>
      <Header $colors={HEADER_COLORS}>
        <Typography $variant="title-3-inter-bold">{t("apRegulated.home.title")}</Typography>
      </Header>

      <PageContent>
        <PageContentWrapper>
          <Wrapper>
            <AcceleratedPayoutsColumn>
              <AcceleratedPayoutsSection setDisplayedDetailsId={setDisplayedDetailsId} />
            </AcceleratedPayoutsColumn>
            <MarketplaceTransfersColumn>
              <MarketplaceTransfersOverview />
            </MarketplaceTransfersColumn>
          </Wrapper>

          <AcceleratedPayoutDetails open={Boolean(displayedDetailsId)} setDisplayedDetailsId={setDisplayedDetailsId} />
        </PageContentWrapper>
      </PageContent>
    </PageWrapper>
  )
}

const PageWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoints.mobileS}) {
    height: calc(100vh - 4.25rem);
  }
`

const PageContent = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`

const PageContentWrapper = styled.main`
  padding: 1.5rem 3rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 1.5rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  gap: 0.75rem;

  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
  }
`

const AcceleratedPayoutsColumn = styled.div`
  flex: 2;

  @media (max-width: ${breakpoints.tablet}) {
    flex: 1;
  }
`

const MarketplaceTransfersColumn = styled.div`
  flex: 1;
`
