import { gql } from "@apollo/client"

export const LIST_MERCHANT_USERS = gql`
  query {
    listMerchantUsers {
      id
      firstname
      lastname
      email
      phone
      status
      role
      isLegalRepresentative
      createdAt
    }
  }
`

export const RESEND_MERCHANT_USER_INVITATION = gql`
  mutation resendMerchantUserInvitationMutation($userId: String!) {
    resendMerchantUserInvitation(userId: $userId)
  }
`

export const CHANGE_MERCHANT_USER_STATUS = gql`
  mutation changeMerchantUserStatusMutation($userId: String!, $status: MerchantUserStatus!) {
    changeMerchantUserStatus(userId: $userId, status: $status) {
      id
      firstname
      lastname
      email
      phone
      status
    }
  }
`

export const CHANGE_MERCHANT_USER_ROLE = gql`
  mutation changeMerchantUserRoleMutation($userId: String!, $role: MerchantUserRole!) {
    changeMerchantUserRole(userId: $userId, role: $role) {
      id
    }
  }
`

export enum UserStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  INVITED = "INVITED",
}

export type MerchantUser = {
  id: string
  firstname: string
  lastname: string
  email: string
  phone: string
  status: UserStatus
}
